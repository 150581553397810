import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faClock, faCar, faCrown, faUser, faInfoCircle, faPhone, faUndoAlt, faCheck, faHourglassStart, faHourglassEnd, faUserCheck, faUserTimes, faPlus, faTrash, faHandsHelping, faChalkboardTeacher, faBackspace, faBackward, faForward, faUserGraduate, faBandAid, faAmbulance, faUserFriends, faUserAstronaut, faList, faUsers, faCodeBranch, faUserNurse } from '@fortawesome/free-solid-svg-icons';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './Turni.css';
import { isMobile } from 'react-device-detect';

class Turni extends React.Component {
  
  constructor(props) {
    super(props);

    this.state = {
      shifts: null,
      equipesNames: [],
      pastShifts: false,
      shift: null,
      newShift: null,
      mergeShifts: [],
      externalUser: null,
      avail: null,
      addEquipeRole: null,
      filterActivities: [],
      filterTeams: [],
      shiftsFilter: false,
      days: ['Domenica', 'Lunedì', 'Martedì', 'Mercoledì', 'Giovedì', 'Venerdì', 'Sabato'],
      getShiftsInt: null,
      setShiftTime: null,
      setEquipesTime: null,
    }
  }

  componentDidMount() {

    if(window.location.hash.indexOf("?t=") !== -1) {
      const shiftN = window.location.hash.slice(window.location.hash.indexOf("?t=")+3);
      
      this.getShift(shiftN);

      this.setState({
        //filterActivities: this.props.activities.filter(a => this.props.user.subs.some(s => s === 'a:'+a.n)).map(a => a.name),
        filterActivities: this.props.activities.map(a => a.name),
        filterTeams: this.props.teams.map(t => t.name)
      });
    } else {
      this.getShifts();

      this.setState({
        getShiftsInt: setInterval(() => this.getShifts(), 15000),
        //filterActivities: this.props.activities.filter(a => this.props.user.subs.some(s => s === 'a:'+a.n)).map(a => a.name),
        filterActivities: this.props.activities.map(a => a.name),
        filterTeams: this.props.teams.filter(t => this.props.user.subs.some(s => s === 't:'+t.n)).map(a => a.name)
      });
    }
  }

  componentWillUnmount() {

    if(this.state.getShiftsInt !== null)
      clearTimeout(this.state.getShiftsInt);
      
    if(this.state.setShiftTime !== null) {
      clearTimeout(this.state.setShiftTime);

      if(this.state.shift !== null)
        this.setShift(this.state.shift);
    }

    if(this.state.setEquipesTime !== null) {
      clearTimeout(this.state.setEquipesTime);
      //this.setEquipes();
    }
  }

  render() {

    if(this.state.externalUser !== null) {

      return(
        this.externalUser()
      )
    } else if(this.state.avail !== null) {

      return(
        this.avail()
      )
    } else if(this.state.newShift !== null) {

      return(
        this.newShift()
      )
    } else if(this.state.shift !== null) {

      return(
        this.shift()
        )
    } else if(this.state.shiftsFilter) {

      return(
        this.shiftsFilter()
      )
    } else {
      
      return(
        this.shifts()
      )
    }
  }

  externalUser() {

    const s = this.state.shift;

    return(
      <div id="utente-esterno">
        <button className="back" onClick={() => this.setState({
          externalUser: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>Utente esterno</h1>

        <form onSubmit={o => {
          o.preventDefault();

          if(this.state.externalUser.name !== undefined && this.state.externalUser.surname !== undefined) {

            let newE = s.equipes.filter(e => e.n === this.state.addEquipeRole.n)[0];
            let newV = this.state.externalUser.name + " " + this.state.externalUser.surname;

            switch(this.state.addEquipeRole.pos) {
              case "Patente 5":
                if(newE.pat5 === null)
                  newE.pat5 = newV;
                else
                  newE.pat5 += ", "+newV;
                break;
              case "Patente 4":
                if(newE.pat4 === null)
                  newE.pat4 = newV;
                else
                  newE.pat4 += ", "+newV;
                break;
              case "CS":
                if(newE.cs === null)
                  newE.cs = newV;
                else
                  newE.cs += ", "+newV;
                break;
              case "socc1":
                if(newE.socc1 === null)
                  newE.socc1 = newV;
                else
                  newE.socc1 += ", "+newV;
                break;
              case "socc2":
                if(newE.socc2 === null)
                  newE.socc2 = newV;
                else
                  newE.socc2 += ", "+newV;
                break;
              case "socc3":
                if(newE.socc3 === null)
                  newE.socc3 = newV;
                else
                  newE.socc3 += ", "+newV;
                break;
              case "vol1":
                if(newE.vol1 === null)
                  newE.vol1 = newV;
                else
                  newE.vol1 += ", "+newV;
                break;
              case "vol2":
                if(newE.vol2 === null)
                  newE.vol2 = newV;
                else
                  newE.vol2 += ", "+newV;
                break;
              case "vol3":
                if(newE.vol3 === null)
                  newE.vol3 = newV;
                else
                  newE.vol3 += ", "+newV;
                break;
              case "OSR":
                if(newE.osr === null)
                  newE.osr = newV;
                else
                  newE.osr += ", "+newV;
                break;
              default:
                break;
            }

            let newS = s;
            newS.equipes = newS.equipes.map(e2 => {
              if(e2.n === newE.n)
                return newE;
              return e2;
            });
            this.setState({
              shift: newS,
              addEquipeRole: null,
              externalUser: null,
              setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
            });
            
            if(this.state.externalUser.email !== undefined) {
              
              window.open(
                "mailto:"+this.state.externalUser.email+
                "?subject="+encodeURIComponent("[CRIsdoGEST] "+(s.customEvent !== null ? s.customEvent : s.eventName)+" "+new Date(s.start.replace(" ", "T")).toLocaleString())+
                "&body="+encodeURIComponent(
                  "Ciao"+(this.state.externalUser.name !== undefined ? " "+this.state.externalUser.name : "")+"!"+
                  "\n\nSei stato aggiunto all'organizzazione del "+(s.customEvent !== null ? s.customEvent : s.eventName)+" del "+ new Date(s.start.replace(" ", "T")).toLocaleString() +";"+
                  "\nsegui il link qui sotto per visualizzare tutti i dettagli."+
                  "\n\n\n"+this.props.user.name+
                  (this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).map(a => "\nResponsabile "+a.name) : "")+
                  (this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).map(a => "\nCollaboratore "+a.name) : "")+
                  (this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).map(t => "\nResponsabile "+t.name) : "")+
                  (this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).map(t => "\nCollaboratore "+t.name) : "")+
                  "\nhttps://cristinaweb.rossini.dev"
                ), "_blank"
              );
            }
          }
        }}>
          <div className="name">
            <input type="text" onChange={o => {
              let newU = this.state.externalUser;
              newU.name = o.target.value !== "" ? o.target.value : undefined;
              this.setState({
                externalUser: newU
              })
            }} placeholder="* Nome" />
            <input type="text" onChange={o => {
              let newU = this.state.externalUser;
              newU.surname = o.target.value !== "" ? o.target.value : undefined;
              this.setState({
                externalUser: newU
              })
            }} placeholder="* Cognome" />
          </div>

          <input name="email" type="email" onChange={o => {
            let newU = this.state.externalUser;
            newU.email = o.target.value !== "" ? o.target.value : undefined;
            this.setState({
              externalUser: newU
            })
          }} placeholder="Email" />

          { this.state.externalUser.name !== undefined && this.state.externalUser.surname !== undefined &&
            <button className="action" type="submit"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button>
          }
        </form>
      </div>
    )
  }

  avail() {

    const s = this.state.shift;
    const a = this.state.avail;

    return(
      <div id="disponibilita">
        <button className="action" onClick={() => {

          let newS = this.state.shift;
          newS.avails = newS.avails.every(a2 => ! (a2.user === a.user && a2.shift === a.shift)) ? newS.avails.concat(this.state.avail) : newS.avails.map(a2 => {
            if(a2.user === a.user && a2.shift === a.shift)
              return this.state.avail;
            return a2;
          });
          this.setAvail().then(() => this.setState({
            avail: null,
            shift: newS,
          }));
        }}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button>
        <h1>Disponibilità {new Date(s.start.replace(" ", "T")).getDate()}/{new Date(s.start.replace(" ", "T")).getMonth()+1}</h1>

        { this.props.user.id !== a.user && <h2>{ a.name }</h2> }

        { a.avail !== 0 && <div>

          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassStart } />
            <input type="datetime-local" value={ a.start !== null ? a.start.replace(/ /, "T") : s.start.replace(/ /, "T") } onChange={o => {

              let newA = a;
              newA.start = o.target.value.replace("T", " ");

              this.setState({
                avail: newA
              })
            }} />
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassEnd } />
            <input type="datetime-local" value={ a.end !== null ? a.end.replace(/ /, "T") : s.end.replace(/ /, "T") } onChange={o => {

              let newA = a;
              newA.end = o.target.value.replace("T", " ");

              this.setState({
                avail: newA
              })
            }} />
          </p>

        </div> }

        <textarea value={ a.info !== null ? a.info : "" } onChange={o => {

          let newA = a;
          newA.info = o.target.value !== "" ? o.target.value : null;

          this.setState({
            avail: newA
          })
        }} placeholder={ a.avail === 1 ? "Informazioni" : "Motivazione / Informazioni" } />

        { a.avail === 0 && <button onClick={() => {

          let newA = a;
          newA.avail = 1;

          this.setState({
            avail: newA
          })
        }}>Aggiungi la disponibilità</button> }

        { a.avail === 1 && <button onClick={() => {

          let newA = a;
          newA.avail = 0;

          this.setState({
            avail: newA
          })
        }}>Rimuovi la disponibilità</button> }
        
      </div>
    )
  }

  shift() {

    const s = this.state.shift;
    const shiftUsers = s.team !== null ? this.props.teams.filter(t => t.n === s.team)[0].users : this.props.activities.filter(a => a.n === s.activity)[0].users;
    let lockDate = new Date(s.start.replace(" ", "T")); lockDate.setDate(new Date(s.start.replace(" ", "T")).getDate() - s.lockDays);

    const availsLi = s.avails.filter(a => a.avail === 1).map((a, i) => {

      const isIstr = a.roles.some(r => r === "Istruttore TSSE");
      const isMon = a.roles.some(r => r === "mon");
      const isTutor = a.roles.some(r => r === "Tutor");
      const isPat5 = a.roles.some(r => r === "Patente 5");
      const isPat4 = a.roles.some(r => r === "Patente 4");
      const isCs = a.roles.some(r => r === "CS");
      const isSocc = a.roles.some(r => r === "Soccorritore");
      const isPc = a.roles.some(r => r === "OPEM");
      const isAdd = a.roles.some(r => r === "Socc in Add");
      const isOsr = a.roles.some(r => r === "OSR");

      return(
        <li key={i}>
          {/* eslint-disable-next-line */}
          <p className="name">{ (s.youreAdmin === 1 || s.youreMod === 1) && new Date(s.end.replace(" ", "T")) >= new Date() && a.id !== this.props.user.id ? <a clickable="true" onClick={() => this.setState({
            avail: a
          })} title="Modifica disponibilità">{ a.info !== null ? a.name+" ("+a.info+")" : a.name }</a> : a.info !== null ? a.name+" ("+a.info+")" : a.name }</p>
          <p className="time">{a.start.substr(11, 5)}-{a.end.substr(11, 5)}</p>
          <p className="roles">
            { isIstr && (a.n === undefined || a.n === 1 || a.n === 6) ? <FontAwesomeIcon className="fa-fw" icon={ faUserGraduate } title="Istruttore TSSE (include Tutor, CS, Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
            { isMon && (a.n === undefined || a.n === 6) ? <FontAwesomeIcon className="fa-fw" icon={ faBandAid } title="Monitore CRI" style={{ cursor: "context-menu" }} /> : "" }
            { isTutor && (a.n === undefined || a.n === 1) ? <FontAwesomeIcon className="fa-fw" icon={ faUserFriends } title="Tutor (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
            { isPat5 && (a.n === undefined || a.n === 1) ? <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } title="Patente 5 (include Patente 4)" style={{ cursor: "context-menu" }} /> : "" }
            { isPat4 && (a.n === undefined || a.n === 1 || a.n === 2) ? <FontAwesomeIcon className="fa-fw" icon={ faCar } title="Patente 4" style={{ cursor: "context-menu" }} /> : "" }
            { isCs && (a.n === undefined || a.n === 1) ? <FontAwesomeIcon className="fa-fw" icon={ faCrown } title="Capo Servizio (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
            { isSocc && (a.n === undefined || a.n === 1) ? <FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
            { isPc && (a.n === undefined || a.n === 3) ? <FontAwesomeIcon className="fa-fw" icon={ faUserAstronaut } title="OPEM" style={{ cursor: "context-menu" }} /> : "" }
            { isAdd && (a.n === undefined || a.n === 1) ? <FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } title="Soccorritore in Addestramento (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
            { isOsr && (a.n === undefined || a.n === 1 || a.n === 3) ? <FontAwesomeIcon className="fa-fw" icon={ faPhone } title="Operatore Sala Radio" style={{ cursor: "context-menu" }} /> : "" }
          </p>
        </li>
      )
    });

    const equipesEditLi = s.equipes.map((e, i) => {

      return(
        <li key={i}>
          <h3>
            <input list="equipes-names" type="text" disabled={ e.n === undefined } value={ e.name !== null ? e.name : "" } onChange={o => {
              if(this.state.setEquipesTime !== null)
                clearTimeout(this.state.setEquipesTime);
              
              let newE = e;
              newE.name = o.target.value !== "" ? o.target.value : null;
              let newS = s;
              newS.equipes = newS.equipes.map(e2 => {
                if(e2.n === e.n)
                  return newE;
                return e2;
              });
              this.setState({
                shift: newS,
                setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
              });
            }} placeholder="Nome / Mezzo" />
            { e.n !== undefined && <button style={{ padding: "5px", margin: "0px" }} onClick={() => {
              let newE = e;
              newE.delete = 1;
              let newS = s;
              newS.equipes = newS.equipes.map(e2 => {
                if(e2.n === e.n)
                  return newE;
                return e2;
              })
              this.setState({
                shift: newS
              })
              this.setEquipes().then(() => {
                newS.equipes = newS.equipes.filter(e2 => e2.n !== e.n);
                this.setState({
                  shift: newS
                })
              });
            }} title="Elimina"><FontAwesomeIcon className="fa-fw" icon={ faTrash } /></button> }
          </h3>
          <p className="time">
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassStart } title="Inizio" />
            <input type="datetime-local" disabled={ e.n === undefined } value={ e.start.replace(" ", "T") } onChange={o => {
            if(this.state.setEquipesTime !== null)
              clearTimeout(this.state.setEquipesTime);
            
            let newE = e;
            newE.start = o.target.value;
            let newS = s;
            newS.equipes = newS.equipes.map(e2 => {
              if(e2.n === e.n)
                return newE;
              return e2;
            });
            this.setState({
              shift: newS,
              setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
            });
          }} /><br />
          <FontAwesomeIcon className="fa-fw" icon={ faHourglassEnd } title="Fine" />
          <input type="datetime-local" disabled={ e.n === undefined } value={ e.end.replace(" ", "T") } onChange={o => {
            if(this.state.setEquipesTime !== null)
              clearTimeout(this.state.setEquipesTime);

            let newE = e;
            newE.end = o.target.value;
            let newS = s;
            newS.equipes = newS.equipes.map(e2 => {
              if(e2.n === e.n)
                return newE;
              return e2;
            });
            this.setState({
              shift: newS,
              setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
            })

          }} /></p>
          
          <div className="equipe">

            <ul>
              <li help={ e.help !== null && e.help.some(h => h === "Patente 5") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } title="Patente 5" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.pat5 }</p>
                { e.pat5 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.pat5.indexOf(", ") !== -1) {
                    e.pat5 = e.pat5.split(", ");
                    e.pat5.pop();
                    e.pat5 = e.pat5.join(", ");
                  } else {
                    e.pat5 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Patente 5"];
                  } else if(e.help.every(h => h !== "Patente 5")) {

                    e.help = e.help.concat("Patente 5");
                  } else if(e.help.length === 1 && e.help[0] === "Patente 5") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Patente 5");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Patente 5")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "Patente 5" ? {
                    n: e.n,
                    pos: "Patente 5",
                    role: "Patente 5",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "Patente 5" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Patente 4") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faCar } title="Patente 4" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.pat4 }</p>
                { e.pat4 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.pat4.indexOf(", ") !== -1) {
                    e.pat4 = e.pat4.split(", ");
                    e.pat4.pop();
                    e.pat4 = e.pat4.join(", ");
                  } else {
                    e.pat4 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Patente 4"];
                  } else if(e.help.every(h => h !== "Patente 4")) {

                    e.help = e.help.concat("Patente 4");
                  } else if(e.help.length === 1 && e.help[0] === "Patente 4") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Patente 4");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Patente 4")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "Patente 4" ? {
                    n: e.n,
                    pos: "Patente 4",
                    role: "Patente 4",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "Patente 4" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "CS") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faCrown } title="CS" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.cs }</p>
                { e.cs !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.cs.indexOf(", ") !== -1) {
                    e.cs = e.cs.split(", ");
                    e.cs.pop();
                    e.cs = e.cs.join(", ");
                  } else {
                    e.cs = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["CS"];
                  } else if(e.help.every(h => h !== "CS")) {

                    e.help = e.help.concat("CS");
                  } else if(e.help.length === 1 && e.help[0] === "CS") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "CS");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "CS")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "CS" ? {
                    n: e.n,
                    pos: "CS",
                    role: "CS",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "CS" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Soccorritore1") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.socc1 }</p>
                { e.socc1 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.socc1.indexOf(", ") !== -1) {
                    e.socc1 = e.socc1.split(", ");
                    e.socc1.pop();
                    e.socc1 = e.socc1.join(", ");
                  } else {
                    e.socc1 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Soccorritore1"];
                  } else if(e.help.every(h => h !== "Soccorritore1")) {

                    e.help = e.help.concat("Soccorritore1");
                  } else if(e.help.length === 1 && e.help[0] === "Soccorritore1") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Soccorritore1");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Soccorritore1")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "socc1" ? {
                    n: e.n,
                    pos: "socc1",
                    role: "Soccorritore",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "socc1" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Soccorritore2") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.socc2 }</p>
                { e.socc2 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.socc2.indexOf(", ") !== -1) {
                    e.socc2 = e.socc2.split(", ");
                    e.socc2.pop();
                    e.socc2 = e.socc2.join(", ");
                  } else {
                    e.socc2 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Soccorritore2"];
                  } else if(e.help.every(h => h !== "Soccorritore2")) {

                    e.help = e.help.concat("Soccorritore2");
                  } else if(e.help.length === 1 && e.help[0] === "Soccorritore2") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Soccorritore2");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Soccorritore2")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "socc2" ? {
                    n: e.n,
                    pos: "socc2",
                    role: "Soccorritore",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "socc2" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Soccorritore3") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } title="Soccorritore o Socc in Add" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.socc3 }</p>
                { e.socc3 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.socc3.indexOf(", ") !== -1) {
                    e.socc3 = e.socc3.split(", ");
                    e.socc3.pop();
                    e.socc3 = e.socc3.join(", ");
                  } else {
                    e.socc3 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Soccorritore3"];
                  } else if(e.help.every(h => h !== "Soccorritore3")) {

                    e.help = e.help.concat("Soccorritore3");
                  } else if(e.help.length === 1 && e.help[0] === "Soccorritore3") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Soccorritore3");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Soccorritore3")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "socc3" ? {
                    n: e.n,
                    pos: "socc3",
                    role: "Soccorritore",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "socc3" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Volontario1") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } title="Volontario" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.vol1 }</p>
                { e.vol1 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.vol1.indexOf(", ") !== -1) {
                    e.vol1 = e.vol1.split(", ");
                    e.vol1.pop();
                    e.vol1 = e.vol1.join(", ");
                  } else {
                    e.vol1 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Volontario1"];
                  } else if(e.help.every(h => h !== "Volontario1")) {

                    e.help = e.help.concat("Volontario1");
                  } else if(e.help.length === 1 && e.help[0] === "Volontario1") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Volontario1");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Volontario1")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "vol1" ? {
                    n: e.n,
                    pos: "vol1",
                    role: "Volontario",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "vol1" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Volontario2") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } title="Volontario" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.vol2 }</p>
                { e.vol2 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.vol2.indexOf(", ") !== -1) {
                    e.vol2 = e.vol2.split(", ");
                    e.vol2.pop();
                    e.vol2 = e.vol2.join(", ");
                  } else {
                    e.vol2 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Volontario2"];
                  } else if(e.help.every(h => h !== "Volontario2")) {

                    e.help = e.help.concat("Volontario2");
                  } else if(e.help.length === 1 && e.help[0] === "Volontario2") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Volontario2");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Volontario2")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "vol2" ? {
                    n: e.n,
                    pos: "vol2",
                    role: "Volontario",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "vol2" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "Volontario3") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } title="Volontario" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.vol3 }</p>
                { e.vol3 !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.vol3.indexOf(", ") !== -1) {
                    e.vol3 = e.vol3.split(", ");
                    e.vol3.pop();
                    e.vol3 = e.vol3.join(", ");
                  } else {
                    e.vol3 = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["Volontario3"];
                  } else if(e.help.every(h => h !== "Volontario3")) {

                    e.help = e.help.concat("Volontario3");
                  } else if(e.help.length === 1 && e.help[0] === "Volontario3") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "Volontario3");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "Volontario3")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "vol3" ? {
                    n: e.n,
                    pos: "vol3",
                    role: "Volontario",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "vol3" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
              <li help={ e.help !== null && e.help.some(h => h === "OSR") ? "true" : "" }>
                <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faPhone } title="OSR" style={{ cursor: "context-menu" }} /></p>
                <p className="names">{ e.osr }</p>
                { e.osr !== null ? <button onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.osr.indexOf(", ") !== -1) {
                    e.osr = e.osr.split(", ");
                    e.osr.pop();
                    e.osr = e.osr.join(", ");
                  } else {
                    e.osr = null;
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Rimuovi l'ultimo componente"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> : <button className="help" onClick={() => {
                  if(this.state.setEquipesTime !== null)
                    clearTimeout(this.state.setEquipesTime);

                  let newE = e;
                  if(e.help === null) {
                    
                    e.help = ["OSR"];
                  } else if(e.help.every(h => h !== "OSR")) {

                    e.help = e.help.concat("OSR");
                  } else if(e.help.length === 1 && e.help[0] === "OSR") {
                    
                    e.help = null;
                  } else {

                    e.help = e.help.filter(h => h !== "OSR");
                  }
                  let newS = s;
                  newS.equipes = newS.equipes.map(e2 => {
                    if(e2.n === e.n)
                      return newE;
                    return e2;
                  });
                  this.setState({
                    shift: newS,
                    setEquipesTime: setTimeout(() => this.setEquipes(), 200),
                  });
                }} title="Chiedi personale in aiuto"><FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                { (e.help === null || e.help.every(h => h !== "OSR")) && <button onClick={() => this.setState({
                  addEquipeRole: this.state.addEquipeRole === null || this.state.addEquipeRole.n !== e.n || this.state.addEquipeRole.pos !== "OSR" ? {
                    n: e.n,
                    pos: "OSR",
                    role: "OSR",
                  } : null,
                })} active={ this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && this.state.addEquipeRole.pos === "OSR" ? "true" : "" } title="Aggiungi componente"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
              </li>
            </ul>

            { this.state.addEquipeRole !== null && this.state.addEquipeRole.n === e.n && <select onChange={o => {
              if(this.state.setEquipesTime !== null)
                clearTimeout(this.state.setEquipesTime);

              let newV = o.target.value;

              if(o.target.value === "+") {

                this.setState({
                  externalUser: {}
                })
              } else if(newV !== "") {

                let newE = s.equipes.filter(e => e.n === this.state.addEquipeRole.n)[0];

                if(newV.indexOf(" ") !== -1 && s.avails.filter(a => a.avail === 1 && a.name.indexOf(newV.slice(newV.indexOf(" ")+1)) !== -1).length === 1) {
                  newV = newV.slice(newV.indexOf(" ")+1);
                }
                // else {
                //   s.avails.filter(a => a.avail === 1 && a.name.indexOf(newV.slice(newV.indexOf(" ")+1)) !== -1).forEach(a => {
                //     if(JSON.stringify(newE).indexOf(a.name) === -1) {
                //       newE = JSON.parse(JSON.stringify(newE).replace(newV.slice(newV.indexOf(" ")+1), a.name));
                //     }
                //   })
                // }

                switch(this.state.addEquipeRole.pos) {
                  case "Patente 5":
                    if(newE.pat5 === null)
                      newE.pat5 = newV;
                    else
                      newE.pat5 += ", "+newV;
                    break;
                  case "Patente 4":
                    if(newE.pat4 === null)
                      newE.pat4 = newV;
                    else
                      newE.pat4 += ", "+newV;
                    break;
                  case "CS":
                    if(newE.cs === null)
                      newE.cs = newV;
                    else
                      newE.cs += ", "+newV;
                    break;
                  case "socc1":
                    if(newE.socc1 === null)
                      newE.socc1 = newV;
                    else
                      newE.socc1 += ", "+newV;
                    break;
                  case "socc2":
                    if(newE.socc2 === null)
                      newE.socc2 = newV;
                    else
                      newE.socc2 += ", "+newV;
                    break;
                  case "socc3":
                    if(newE.socc3 === null)
                      newE.socc3 = newV;
                    else
                      newE.socc3 += ", "+newV;
                    break;
                  case "vol1":
                    if(newE.vol1 === null)
                      newE.vol1 = newV;
                    else
                      newE.vol1 += ", "+newV;
                    break;
                  case "vol2":
                    if(newE.vol2 === null)
                      newE.vol2 = newV;
                    else
                      newE.vol2 += ", "+newV;
                    break;
                  case "vol3":
                    if(newE.vol3 === null)
                      newE.vol3 = newV;
                    else
                      newE.vol3 += ", "+newV;
                    break;
                  case "OSR":
                    if(newE.osr === null)
                      newE.osr = newV;
                    else
                      newE.osr += ", "+newV;
                    break;
                  default:
                    break;
                }
                let newS = s;
                newS.equipes = newS.equipes.map(e2 => {
                  if(e2.n === e.n)
                    return newE;
                  return e2;
                });
                this.setState({
                  shift: newS,
                  addEquipeRole: null,
                  setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
                });
              }
            }}>
              <option key={-1} value="">-- Aggiungi { this.state.addEquipeRole.role.toLowerCase() } --</option>
              { s.avails
                .filter(a => a.avail === 1 && new Date(a.start.replace(" ", "T")) <= new Date(e.start.replace(" ", "T")) && new Date(a.end.replace(" ", "T")) >= new Date(e.end.replace(" ", "T")) && a.roles.some(r => this.state.addEquipeRole.role === "Volontario" || this.state.addEquipeRole.role === "OSR" || r === this.state.addEquipeRole.role || (this.state.addEquipeRole.role === "Patente 4" && r === "Patente 5") || (this.state.addEquipeRole.role === "CS" && r === "Istruttore TSSE") || (this.state.addEquipeRole.role === "Soccorritore" && (r === "Istruttore TSSE" || r === "Tutor" || r === "CS" || r === "Patente 5")) || (this.state.addEquipeRole.pos === "socc3" && r === "Socc in Add") ))
                .map((a, i2) => {
                  return(
                    <option key={i2} value={a.name}>{a.name} {a.start.substr(11, 5)}-{a.end.substr(11, 5)}</option>
                  )
                })
              }
              <option key={-2} value="+">- Utente esterno -</option>
            </select> }

            <div className="info">
              <p><FontAwesomeIcon className="fa-fw" icon={ faInfoCircle } title="Informazioni" style={{ cursor: "context-menu" }} /></p>
              <textarea disabled={ e.n === undefined } value={ e.info !== null ? e.info : "" } onChange={o => {
                if(this.state.setEquipesTime !== null)
                  clearTimeout(this.state.setEquipesTime);

                let newE = e;
                newE.info = o.target.value !== "" ? o.target.value : null;
                let newS = s;
                newS.equipes = newS.equipes.map(e2 => {
                  if(e2.n === e.n)
                    return newE;
                  return e2;
                });
                this.setState({
                  shift: newS,
                  setEquipesTime: setTimeout(() => this.setEquipes(), 1000),
                });
              }} placeholder="Informazioni" />
            </div>
          </div>
        </li>
      )
    })

    let myAvail = s.avails.filter(a => a.user === this.props.user.id);
    if(myAvail.length === 0) {

      myAvail = null;
    } else {

      myAvail = myAvail[0];
    }

    return(
      <div id="turno">
        <button className="back" onClick={() => {

          if(this.state.setShiftTime !== null) {
            clearTimeout(this.state.setShiftTime);
            this.setShift(this.state.shift).then(() => this.getShifts());
          }
          if(this.state.setEquipesTime !== null) {
            clearTimeout(this.state.setEquipesTime);
            //this.setEquipes();
          }
          if(this.state.getShiftsInt !== null)
            clearTimeout(this.state.getShiftsInt);

          this.setState({
            shift: null,
            getShiftsInt: setInterval(() => this.getShifts(), 15000),
          });

          if(window.location.hash.indexOf("?t=") !== -1)
            window.location.hash = window.location.hash.slice(0, window.location.hash.indexOf("?t="))
        }}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>
          { s.activityName }
          { s.activity !== null && <br /> }
          { s.customEvent !== null ? s.customEvent : s.eventName } {new Date(s.start.replace(" ", "T")).getDate()}/{new Date(s.start.replace(" ", "T")).getMonth()+1}
        </h1>

        { (s.youreAdmin === 1 || s.youreMod === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin") && new Date(s.end.replace(" ", "T")) >= new Date() &&
        <div id="amministrazione">
          { (this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") && <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUsers } /> 
            <select value={s.team} onChange={o => {
              if(this.state.setShiftTime !== null)
                clearTimeout(this.state.setShiftTime);

              let newS = s;
              if(o.target.value !== "")
                newS.team = this.props.teams.filter(t => t.n.toString() === o.target.value)[0].n;
              else
                newS.team = null;
              
              this.setState({
                shift: newS,
                setShiftTime: setTimeout(() => this.setShift(newS), 1000),
              });
            }}>
              { this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) && <option key={-1} value="">-- Nessuna squadra --</option> }
              { this.props.teams.filter(t => t.youreAdmin === 1 || t.youreMod === 1 || this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1))
                .map((t, i) => {
                  return(
                    <option key={i} value={t.n}>{ t.name }</option>
                  )
                })
              }
            </select>
          </p> }
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassStart } />
            <input type="datetime-local" value={ s.start.replace(/ /, "T") } onChange={o => {
              if(this.state.setShiftTime !== null)
                clearTimeout(this.state.setShiftTime);

              let newS = s;
              newS.start = o.target.value.replace("T", " ");

              this.setState({
                shift: newS,
                setShiftTime: setTimeout(() => this.setShift(newS), 1000),
              });
            }} />
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassEnd } />
            <input type="datetime-local" value={ s.end.replace(/ /, "T") } onChange={o => {
              if(this.state.setShiftTime !== null)
                clearTimeout(this.state.setShiftTime);

              let newS = s;
              newS.end = o.target.value.replace("T", " ");

              this.setState({
                shift: newS,
                setShiftTime: setTimeout(() => this.setShift(newS), 1000),
              });
            }} />
          </p>
          <p>
            Chiusura raccolta: <input type="number" min={0} max={99} value={ s.lockDays } onChange={o => {
              if(this.state.setShiftTime !== null)
                clearTimeout(this.state.setShiftTime);

              if(o.target.value !== "" && o.target.valueAsNumber >= 0 && o.target.valueAsNumber <= 99) {
                let newS = s;
                newS.lockDays = o.target.value;
                
                this.setState({
                  shift: newS,
                  setShiftTime: setTimeout(() => this.setShift(newS), 1000)
                });
              } else if(o.target.value !== "" && o.target.valueAsNumber < 0) {
                confirmAlert({
                  title: "Errore",
                  message: "Valore minimo: 0 (inizio turno)",
                  buttons: [
                    {
                      label: "OK"
                    }
                  ]
                });
              } else if(o.target.value !== "" && o.target.valueAsNumber > 99) {
                confirmAlert({
                  title: "Errore",
                  message: "Valore massimo: 99",
                  buttons: [
                    {
                      label: "OK"
                    }
                  ]
                });
              }
            }} /> giorni prima<br />
            Reminder { parseInt(s.lockDays)+1 } giorni prima
          </p>
        </div> }

        <h2>Disponibilità</h2>

        { new Date(s.end.replace(" ", "T")) >= new Date() ? (
          myAvail === null ? <button onClick={() => {
            let newA = Object.assign({
              user: this.props.user.id,
              avail: s.youreSub === 1 ? 1 : 1, // TODO: to change in 1 : 2
              start: s.start,
              end: s.end,
              info: null,
            }, this.props.user, {
              shift: this.state.shift.n
            });

            this.setState({
              avail: newA
            })
          }} disabled={ new Date() >= lockDate } title={ new Date() >= lockDate ? "Raccolta bloccata, contattare direttamente il responsabile per cambiare disponibilità" : "" }>
            <FontAwesomeIcon className="fa-fw" icon={ faClock } />
            Comunica disponibilità
          </button> : ( myAvail.avail === 1 ? <button onClick={() => this.setState({
            avail: myAvail
          })} disabled={ new Date() >= lockDate } title={ new Date() >= lockDate ? "Raccolta bloccata, contattare direttamente il responsabile per cambiare disponibilità" : "" }>
            <FontAwesomeIcon className="fa-fw" icon={ faUserCheck } />
            Disponibile {myAvail.start.substr(11, 5)}-{myAvail.end.substr(11, 5)}
          </button> : <button onClick={() => this.setState({
            avail: myAvail
          })} disabled={ new Date() >= lockDate } title={ new Date() >= lockDate ? "Raccolta bloccata, contattare direttamente il responsabile per cambiare disponibilità" : "" }>
            <FontAwesomeIcon className="fa-fw" icon={ faUserTimes } />
            Non disponibile
          </button> )
        ) : "" }
        <p>Chiusura raccolta: { lockDate.toLocaleString() }</p>

        <ul className="avails">

          { availsLi.length > 0 && <li key={-1}>
            <p className="name"></p>
            <p className="time">
              <FontAwesomeIcon className="fa-fw" icon={ faHourglassStart } />
              <FontAwesomeIcon className="fa-fw" icon={ faHourglassEnd } />
            </p>
            <p className="roles"></p>
          </li> }

          { availsLi }

          <li key={-2}>
            <p className="name">
              { (s.youreAdmin === 1 || s.youreMod === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") && new Date(s.end.replace(" ", "T")) >= new Date() &&
              shiftUsers.filter(u => s.avails.every(a => a.user !== u.id)).length > 0 &&
              <select onChange={o => {
                
                const u = shiftUsers.filter(u => u.email === o.target.value)[0];
                let newA = Object.assign({
                  user: u.id,
                  avail: 1,
                  start: s.start,
                  end: s.end,
                  info: null,
                }, u, {
                  shift: this.state.shift.n
                });

                this.setState({
                  avail: newA
                })
              }}>
                <option key={-1} value="">-- Aggiungi disponibilità --</option>
                { shiftUsers.filter(u => s.avails.every(a => a.user !== u.id))
                  .map((u, i) => {
                    return(
                      <option key={i} value={u.email}>{ u.name }</option>
                    )
                  })
                }
              </select> }
            </p>
            <p className="time"></p>
            <p className="role"></p>
            <p className="role"></p>
            <p className="role"></p>
            <p className="role"></p>
            <p className="role"></p>
          </li>
        </ul>

        { (s.youreAdmin === 1 || s.youreMod === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") && new Date(s.end.replace(" ", "T")) >= new Date() && [
          <h2 key={0}>Organizzazione</h2>,
          <datalist id="equipes-names" key={1}>
            { this.state.equipesNames.map(en => {
              return <option value={ en } />
            }) }
          </datalist>,
          <ul className="equipes" key={2}>
            { equipesEditLi }
            <div style={{ alignItems: "center", justifyContent: "center" }}>
              <button onClick={() => {
                let newS = s;
                let newEquipes = s.equipes.concat({
                  shift: s.n,
                  name: null,
                  start: s.start,
                  end: s.end,
                  pat5: null,
                  pat4: null,
                  cs: null,
                  socc1: null,
                  socc2: null,
                  socc3: null,
                  vol1: null,
                  vol2: null,
                  vol3: null,
                  osr: null,
                  info: null,
                  help: null,
                })
                newS.equipes = newEquipes;
                this.setState({
                  shift: newS
                });
                this.setEquipes();
              }} title="Aggiungi un'organizzazione"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button>
            </div>
          </ul>
        ]}

        <h2>Indisponibilità</h2>
        <ul className="avails">
          { s.avails.filter(a => a.avail === 0).length > 0 ? s.avails.filter(a => a.avail === 0).map((a, i) => {

            return(
              <li key={i}>
                {/* eslint-disable-next-line */}
                <p className="name">{ (s.youreAdmin === 1 || s.youreMod === 1) && new Date(s.end.replace(" ", "T")) >= new Date() ? <a clickable="true" onClick={() => this.setState({
                  avail: a
                })} title="Modifica disponibilità">{ a.info !== null ? a.name+" ("+a.info+")" : a.name }</a> : a.info !== null ? a.name+" ("+a.info+")" : a.name }</p>
              </li>
            )
          }) : <p>Nessuna</p> }
        </ul>

        { (s.youreAdmin === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin") && new Date(s.end.replace(" ", "T")) >= new Date() &&
        <button style={{ borderColor: "red" }} onClick={() => confirmAlert({
          title: "Attenzione",
          message: "Eliminare definitivamente il turno?",
          buttons: [
            {
              label: "No"
            },
            {
              label: "Si, rimuovi",
              onClick: () => this.delShift().then(() => this.setState({
                shift: null,
                shifts: this.state.shifts.filter(s2 => s2.n !== s.n)
              }))
            }
          ]
        })}>Rimuovi turno</button> }

      </div>
    )
  }

  shifts() {

    const shiftsLi = this.state.shifts === null ? <p><FontAwesomeIcon className="fa-fw fa-spin" icon={ faCircleNotch } /></p> : this.state.shifts
    .filter(s => {
      return s.equipes.some(e => e.help !== null) || (
        (s.activity === null || (this.state.filterActivities !== null && this.state.filterActivities.some(a => a === s.activityName))) &&
        (s.team === null || (this.state.filterTeams !== null && this.state.filterTeams.some(t => t === s.teamName)))
      )
    })
    .map((s, i) => {

      const equipesLi = s.equipes.map((e, i) => {

        return(
          <li key={i}>
            <h3>{ e.name }</h3>
            <p className="time"><FontAwesomeIcon className="fa-fw" icon={ faClock } /> {e.start.substr(11, 5)} - {e.end.substr(11, 5)}</p>
            
            <div className="equipe">
  
              <ul>
                { (e.pat5 !== null || (e.help !== null && e.help.some(h => h === "Patente 5"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Patente 5") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Patente 5") && <i>In cerca di <b>Autista pat. 5</b></i> }
                    { e.pat5 }
                  </p>
                </li> }
                { (e.pat4 !== null || (e.help !== null && e.help.some(h => h === "Patente 4"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Patente 4") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faCar } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Patente 4") && <i>In cerca di <b>Autista pat. 4</b></i> }
                    { e.pat4 }
                  </p>
                </li> }
                { (e.cs !== null || (e.help !== null && e.help.some(h => h === "CS"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "CS") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faCrown } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "CS") && <i>In cerca di <b>CS</b></i> }
                    { e.cs }
                  </p>
                </li> }
                { (e.socc1 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore1"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore1") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Soccorritore1") && <i>In cerca di <b>Soccorritore</b></i> }
                    { e.socc1 }
                  </p>
                </li> }
                { (e.socc2 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore2"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore2") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Soccorritore2") && <i>In cerca di <b>Soccorritore</b></i> }
                    { e.socc2 }
                  </p>
                </li> }
                { (e.socc3 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore3"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore3") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Soccorritore3") && <i>In cerca di <b>Soccorritore</b></i> }
                    { e.socc3 }
                  </p>
                </li> }
                { (e.vol1 !== null || (e.help !== null && e.help.some(h => h === "Volontario1"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario1") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Volontario1") && <i>In cerca di <b>Volontario</b></i> }
                    { e.vol1 }
                  </p>
                </li> }
                { (e.vol2 !== null || (e.help !== null && e.help.some(h => h === "Volontario2"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario2") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Volontario2") && <i>In cerca di <b>Volontario</b></i> }
                    { e.vol2 }
                  </p>
                </li> }
                { (e.vol3 !== null || (e.help !== null && e.help.some(h => h === "Volontario3"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario3") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "Volontario3") && <i>In cerca di <b>Volontario</b></i> }
                    { e.vol3 }
                  </p>
                </li> }
                { (e.osr !== null || (e.help !== null && e.help.some(h => h === "OSR"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "OSR") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faPhone } /></p>
                  <p className="names">
                    { e.help !== null && e.help.some(h => h === "OSR") && <i>In cerca di <b>OSR</b></i> }
                    { e.osr }
                  </p>
                </li> }
              </ul>
  
              { e.info !== null && <p className="info">
                <FontAwesomeIcon className="fa-fw" icon={ faInfoCircle } />
                { e.info }
              </p> }
            </div>
          </li>
        )
      })

      let myAvail = s.avails.filter(a => a.user === this.props.user.id);
      if(myAvail.length === 0) {

        myAvail = null;
      } else {

        myAvail = myAvail[0];
      }

      let startDay, endDay;
      const today = new Date();
      const tomorrow = new Date(today); tomorrow.setDate(tomorrow.getDate() + 1);
      const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1);

      let lockDate = new Date(s.start.replace(" ", "T")); lockDate.setDate(new Date(s.start.replace(" ", "T")).getDate() - s.lockDays);

      switch(new Date(s.start.replace(" ", "T")).toLocaleDateString()) {
        case tomorrow.toLocaleDateString():
          startDay = "Domani";
          break;
        case today.toLocaleDateString():
          startDay = "Oggi";
          break;
        case yesterday.toLocaleDateString():
          startDay = "Ieri";
          break;
        default:
          startDay = this.state.days[new Date(s.start.replace(" ", "T")).getDay()];
      }
      switch(new Date(s.end.replace(" ", "T")).toLocaleDateString()) {
        case tomorrow.toLocaleDateString():
          endDay = "domani";
          break;
        case today.toLocaleDateString():
          endDay = "oggi";
          break;
        case yesterday.toLocaleDateString():
          endDay = "ieri";
          break;
        default:
          endDay = this.state.days[new Date(s.end.replace(" ", "T")).getDay()].toLowerCase()
      }

      return(
        <li key={i}
          mine={ s.youreSub === 1 ? "true" : "" }
          avail={ myAvail !== null ? ( myAvail.avail === 1 ? "true" : "false" ) : (s.youreSub === 1 ? "dk" : "") }
          help={ s.equipes.some(e => e.help !== null) ? "true" : "" }
          onClick={() => this.setState({
            shift: s
          })}
        >
          <h3>
            { startDay } {new Date(s.start.replace(" ", "T")).toLocaleString()}<br />
            <u>{ s.customEvent !== null ? s.customEvent : s.eventName }</u>{ s.team !== null ? " di "+s.teamName : "" }
          </h3>

          <ul className="equipes">
            { equipesLi }
          </ul>

          <div className="footer">
            <p className="avail">
              { myAvail === null ? (
                "Non hai comunicato la tua disponibilità") : (myAvail.avail === 1 ? (
                "Sei disponibile dalle "+myAvail.start.substr(11, 5)+" alle "+myAvail.end.substr(11, 5)) : (
                "Non sei disponibile per il turno"
              )) }
            </p>
            
            { lockDate > new Date() ? <p style={ myAvail === null ? { color: "orange" } : {} }>
              Chiusura raccolta: { lockDate.toLocaleString() }
            </p> : <p style={ myAvail === null ? { color: "red" } : {} }>
              Raccolta chiusa: { lockDate.toLocaleString() }
            </p> }
          </div>
          
          <p>Fine prevista: {endDay} {new Date(s.end.replace(" ", "T")).toLocaleString()}</p>
        </li>
      )
    })

    const helpShiftsLi = this.state.shifts === null ? <p><FontAwesomeIcon className="fa-fw fa-spin" icon={ faCircleNotch } /></p> : this.state.shifts
    .filter(s => s.equipes.some(e => e.help !== null))
    .map((s, i) => {

      const equipesLi = s.equipes.map((e, i) => {

        return(
          <li key={i}>
            <h3>{ e.name }</h3>
            <p className="time"><FontAwesomeIcon className="fa-fw" icon={ faClock } /> {e.start.substr(11, 5)} - {e.end.substr(11, 5)}</p>
            
            <div className="equipe">
  
              <ul>
                { (e.pat5 !== null || (e.help !== null && e.help.some(h => h === "Patente 5"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Patente 5") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Patente 5") ? <i>In cerca di <b>Autista pat. 5</b></i> : e.pat5 }</p>
                </li> }
                { (e.pat4 !== null || (e.help !== null && e.help.some(h => h === "Patente 4"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Patente 4") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Patente 4") ? <i>In cerca di <b>Autista pat. 4</b></i> : e.pat4 }</p>
                </li> }
                { (e.cs !== null || (e.help !== null && e.help.some(h => h === "CS"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "CS") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faCrown } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "CS") ? <i>In cerca di <b>CS</b></i> : e.cs }</p>
                </li> }
                { (e.socc1 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore1"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore1") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Soccorritore1") ? <i>In cerca di <b>Soccorritore</b></i> : e.socc1 }</p>
                </li> }
                { (e.socc2 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore2"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore2") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Soccorritore2") ? <i>In cerca di <b>Soccorritore</b></i> : e.socc2 }</p>
                </li> }
                { (e.socc3 !== null || (e.help !== null && e.help.some(h => h === "Soccorritore3"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Soccorritore3") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Soccorritore3") ? <i>In cerca di <b>Soccorritore</b></i> : e.socc3 }</p>
                </li> }
                { (e.vol1 !== null || (e.help !== null && e.help.some(h => h === "Volontario1"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario1") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Volontario1") ? <i>In cerca di <b>Volontario</b></i> : e.vol1 }</p>
                </li> }
                { (e.vol2 !== null || (e.help !== null && e.help.some(h => h === "Volontario2"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario2") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Volontario2") ? <i>In cerca di <b>Volontario</b></i> : e.vol2 }</p>
                </li> }
                { (e.vol1 !== null || (e.help !== null && e.help.some(h => h === "Volontario3"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "Volontario3") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUser } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "Volontario3") ? <i>In cerca di <b>Volontario</b></i> : e.vol3 }</p>
                </li> }
                { (e.osr !== null || (e.help !== null && e.help.some(h => h === "OSR"))) &&
                <li help={ e.help !== null && e.help.some(h => h === "OSR") ? "true" : "" }>
                  <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faPhone } /></p>
                  <p className="names">{ e.help !== null && e.help.some(h => h === "OSR") ? <i>In cerca di <b>OSR</b></i> : e.osr }</p>
                </li> }
              </ul>
  
              { e.info !== null && <p className="info">
                <FontAwesomeIcon className="fa-fw" icon={ faInfoCircle } />
                { e.info }
              </p> }
            </div>
          </li>
        )
      })

      let myAvail = s.avails.filter(a => a.user === this.props.user.id);
      if(myAvail.length === 0) {

        myAvail = null;
      } else {

        myAvail = myAvail[0];
      }

      let startDay, endDay;
      const today = new Date();
      const tomorrow = new Date(today); tomorrow.setDate(tomorrow.getDate() + 1);
      const yesterday = new Date(today); yesterday.setDate(yesterday.getDate() - 1);

      let lockDate = new Date(s.start.replace(" ", "T")); lockDate.setDate(new Date(s.start.replace(" ", "T")).getDate() - s.lockDays);

      switch(new Date(s.start.replace(" ", "T")).toLocaleDateString()) {
        case tomorrow.toLocaleDateString():
          startDay = "Domani";
          break;
        case today.toLocaleDateString():
          startDay = "Oggi";
          break;
        case yesterday.toLocaleDateString():
          startDay = "Ieri";
          break;
        default:
          startDay = this.state.days[new Date(s.start.replace(" ", "T")).getDay()];
      }
      switch(new Date(s.end.replace(" ", "T")).toLocaleDateString()) {
        case tomorrow.toLocaleDateString():
          endDay = "domani";
          break;
        case today.toLocaleDateString():
          endDay = "oggi";
          break;
        case yesterday.toLocaleDateString():
          endDay = "ieri";
          break;
        default:
          endDay = this.state.days[new Date(s.end.replace(" ", "T")).getDay()].toLowerCase()
      }

      return(
        <li key={i}
          mine={ s.youreSub === 1 ? "true" : "" }
          avail={ myAvail !== null ? ( myAvail.avail === 1 ? "true" : "false" ) : (s.youreSub === 1 ? "dk" : "") }
          help={ s.equipes.some(e => e.help !== null) ? "true" : "" }
          onClick={() => this.setState({
            shift: s
          })}
        >
          <h3>
            { startDay } {new Date(s.start.replace(" ", "T")).toLocaleString()}<br />
            <u>{ s.customEvent !== null ? s.customEvent : s.eventName }</u>{ s.team !== null ? " di "+s.teamName : "" }
          </h3>

          <ul className="equipes">
            { equipesLi }
          </ul>

          <div className="footer">
            <p className="avail">
              { myAvail === null ? (
                "Non hai comunicato la tua disponibilità") : (myAvail.avail === 1 ? (
                "Sei disponibile dalle "+myAvail.start.substr(11, 5)+" alle "+myAvail.end.substr(11, 5)) : (
                "Non sei disponibile per il turno"
              )) }
            </p>
            
            { lockDate > new Date() ? <p style={ myAvail === null ? { color: "orange" } : {} }>
              Chiusura raccolta: { lockDate.toLocaleString() }
            </p> : <p style={ myAvail === null ? { color: "red" } : {} }>
              Raccolta chiusa: { lockDate.toLocaleString() }
            </p> }
          </div>
          
          <p>Fine prevista: {endDay} {new Date(s.end.replace(" ", "T")).toLocaleString()}</p>
        </li>
      )
    })

    return(
      <div id="turni">
        { (this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) || this.props.teams.some(t => t.youreAdmin === 1 || t.youreMod === 1)) &&
        <button className="action" onClick={() => {
          this.setState({
            newShift: {},
            pastShifts: false,
            shifts: null
          });
          setTimeout(() => this.getShifts(), 500);
        }} title="Nuovo turno"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
        
        <div id="shifts-container">
          <div id="shifts-main">
            <div id="shifts-filter">
              <h1>
                { this.state.pastShifts ? "Turni passati" : "Turni" }
                { window.location.hash.indexOf("?t=") !== -1 ? (window.location.hash.slice(window.location.hash.indexOf("?t=")+3).indexOf(",") === -1 ? "Turno" : "Turni")+" "+window.location.hash.slice(window.location.hash.indexOf("?t=")+3) : "" }
              </h1>

              <h2>Filtro</h2>
              <p>
                Attività: { this.state.filterActivities.length === this.props.activities.length ? "Tutte" : (this.state.filterActivities.map(fa => fa+", ") + "In cerca di personale") }
              </p>
              <p>
                Squadre: { this.state.filterTeams.length === this.props.teams.length ? "Tutte" : (this.state.filterTeams.map(ft => ft+", ") + "In cerca di personale") }
              </p>

              { window.location.hash.indexOf("?t=") === -1 && <p>
                { !this.state.pastShifts ? <button onClick={() => {
                  this.setState({
                    pastShifts: true,
                    shifts: null
                  });
                  setTimeout(() => this.getShifts(), 500);
                }}><FontAwesomeIcon className="fa-fw" icon={ faBackward } title="Visualizza turni passati" /></button> : <button onClick={() => {
                  this.setState({
                    pastShifts: false,
                    shifts: null
                  });
                  setTimeout(() => this.getShifts(), 500);
                }} style={{ backgroundColor: "lightgreen" }} title="Torna ai turni futuri"><FontAwesomeIcon className="fa-fw" icon={ faForward } /></button> }
                <button onClick={() => this.setState({
                  shiftsFilter: true
                })}>Modifica filtro</button>
              </p> }
            </div>

            <ul>
              { new Date(this.props.user.created.replace(" ", "T")) <= new Date(Date.now()-1000*3600*24*31*2) && <li key={-1} onClick={() => {}}>
                <h3>Dati utente</h3>

                <p ok={ this.props.user.avails2months >= 4 ? 2 : (this.props.user.avails2months >= 2 ? 1 : 0) }>Negli ultimi 2 mesi { this.props.user.avails2months !== 0 ? "hai fornito "+this.props.user.avails2months : "non hai fornito" } disponibilità</p>
                { new Date(this.props.user.created.replace(" ", "T")) <= new Date(Date.now()-1000*3600*24*30*6) && <p ok={ this.props.user.avails6months >= 12 ? 2 : (this.props.user.avails6months >= 6 ? 1 : 0) }>Negli ultimi 6 mesi { this.props.user.avails6months !== 0 ? "hai fornito "+this.props.user.avails6months : "non hai fornito" } disponibilità</p> }

                {/* <div className="footer">
                  <p></p>
                  <p></p>
                </div> */}
              </li> }
              
              { shiftsLi }
            </ul>

            { window.location.hash.indexOf("?t=") !== -1 && [<p>
              Stai visualizzando solamente il turno cercato
            </p>, <button onClick={() => {
              window.location.hash = window.location.hash.slice(0, window.location.hash.indexOf("?t="));
              this.setState({
                shifts: null
              })
              
              this.getShifts();
            }}>
              <FontAwesomeIcon className="fa-fw" icon={ faList } />Tutti i turni
            </button>] }
          </div>
          { !isMobile && <div id="shifts-bar">
            <h2>In cerca di personale</h2>

            <ul>
              { helpShiftsLi }
            </ul>
          </div> }
        </div>
      </div>
    )
  }

  shiftsFilter() {

    const filterActivitiesLi = this.props.activities.map((a, i) => {

      return (
        <li key={i} active={ this.state.filterActivities.some(a2 => a2 === a.name) ? "true" : "" }>
          <p onClick={() => this.setState({
            filterActivities: [a.name],
            filterTeams: this.props.teams.map(t => t.name)
          })}>{ a.name }</p>
          <input type="checkbox" checked={ this.state.filterActivities.some(a2 => a2 === a.name) } onChange={e => {
            if(e.target.checked) {
              this.setState({
                filterActivities: this.state.filterActivities.concat(a.name)
              })
            } else {
              this.setState({
                filterActivities: this.state.filterActivities.filter(a2 => a2 !== a.name)
              })
            }
          }} />
        </li>
      )
    });

    const filterTeamsLi = this.props.teams.map((t, i) => {

      return (
        <li key={i} active={ this.state.filterTeams.some(t2 => t2 === t.name) ? "true" : "" }>
          <p onClick={() => this.setState({
            filterActivities: this.props.activities.map(a => a.name),
            filterTeams: [t.name]
          })}>{ t.name }</p>
          <input type="checkbox" checked={ this.state.filterTeams.some(t2 => t2 === t.name) } onChange={e => {
            if(e.target.checked) {
              this.setState({
                filterTeams: this.state.filterTeams.concat(t.name)
              })
            } else {
              this.setState({
                filterTeams: this.state.filterTeams.filter(t2 => t2 !== t.name)
              })
            }
          }} />
        </li>
      )
    });

    return(
      <div id="filter">
        <h1>Filtro Turni</h1>
        <button className="action" onClick={() => this.setState({
          shiftsFilter: false
        })}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button>

        <ul>
          <li key={-1} active={ this.state.filterActivities.length + this.state.filterTeams.length === this.props.activities.length + this.props.teams.length ? "true" : "" }>
            <p onClick={() => this.setState({
              filterActivities: this.props.activities.map(a => a.name),
              filterTeams: this.props.teams.map(a => a.name),
            })}>Tutte le attività/squadre</p>
            <input type="checkbox" checked={ this.state.filterActivities.length + this.state.filterTeams.length === this.props.activities.length + this.props.teams.length } onChange={() => this.setState({
              filterActivities: this.props.activities.map(a => a.name),
              filterTeams: this.props.teams.map(a => a.name),
            })} />
          </li>
        </ul>

        <ul>
          { filterActivitiesLi }
        </ul>

        <ul>
          { filterTeamsLi }
        </ul>

        <ul>
          <li key={-2} active="true">
            <p>In cerca di personale</p>
            <input type="checkbox" checked disabled />
          </li>
        </ul>
      </div>
    )
  }

  newShift() {

    const s = this.state.newShift;
    
    return(
      <div id="nuovo-turno">
        <button className="back" onClick={() => this.setState({
          newShift: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>Nuovo turno</h1>

        { !(s.event === undefined && s.customEvent !== undefined) && <span>
          <select onChange={o => {
           let newS = s;
            if(o.target.value !== "") {
              let newA = this.props.activities.filter(a => a.n.toString() === o.target.value)[0];
              newS.activity = newA.n;
              newS.activityName = newA.name;
            } else {
              newS.activity = undefined;
            }
            this.setState({
              newShift: newS
            })
          }}>
            <option key={-1} value="">-- Assegna ad un'attività (facoltativo) --</option>
            { this.props.activities.filter(a => a.youreAdmin === 1 || a.youreMod === 1 || this.props.teams.some(t => t.youreAdmin === 1 || t.youreMod === 1))
              .map((a, i) => {
                return(
                  <option key={i} value={a.n}>{ a.name }</option>
                )
              })
            }
          </select>
          { s.activity === undefined ? <p>Oppure:</p> : "" }
        </span>}

        { s.activity !== undefined && <select onChange={o => {
          let newS = s;
          if(o.target.value === "") {

            newS.event = undefined;
          } else if(o.target.value === "+") {
            
            newS.event = null;
          } else {

            const newA = this.props.activities.filter(a => a.n === s.activity)[0];
            const newE = newA.events.filter(e => e.n.toString() === o.target.value)[0];
            newS.event = newE.n;
            newS.eventName = newE.name;
            newS.startTime = newE.defaultStart;
            newS.endTime = newE.defaultEnd;
            newS.lockDays = newE.defaultLockDays;
          }
          this.setState({
            newShift: newS
          })
        }}>
          <option key={-1} value="">-- Seleziona un evento --</option>
          { this.props.activities.filter(a => a.n === s.activity)[0].events
            .map((e, i) => {
              return(
                <option key={i} value={e.n}>{e.name} { e.defaultStart !== null && e.defaultEnd !== null ? ((e.defaultStart !== null ? e.defaultStart.substr(0, 5) : "?") + " - " + (e.defaultEnd !== null ? e.defaultEnd.substr(0, 5) : "?")) : ""}</option>
              )
            })
          }
          <option key={-1} value="+">- Evento personalizzato -</option>
        </select> }

        { (s.activity === undefined || s.event === null) && <input type="text" value={ s.customEvent !== null ? s.customEvent : "" } onChange={o => {
          let newS = s;
          if(o.target.value === "") {

            newS.customEvent = undefined;
          } else {

            newS.customEvent = o.target.value;
            newS.lockDays = 0;
          }
          this.setState({
            newShift: newS
          })
        }} placeholder="Evento personalizzato" /> }

        { (s.event !== undefined || s.customEvent !== undefined) && <div>
            <select onChange={o => {
            let newS = s;
            if(o.target.value !== "")
              newS.team = this.props.teams.filter(t => t.n.toString() === o.target.value)[0].n;
            else
              newS.team = undefined;
            this.setState({
              newShift: newS
            })
          }}>
            { this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) && <option key={-1} value="">-- Assegna ad una squadra (facoltativo) --</option> }
            { this.props.teams.filter(t => t.youreAdmin === 1 || t.youreMod === 1 || this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1))
              .map((t, i) => {
                return(
                  <option key={i} value={t.n}>{ t.name }</option>
                )
              })
            }
          </select>
          
          <p>
            Chiusura raccolta: <input type="number" min={0} max={99} value={ s.lockDays } onChange={o => {
              if(o.target.value !== "" && o.target.valueAsNumber >= 0 && o.target.valueAsNumber <= 99) {
                let newS = s;
                newS.lockDays = o.target.value;

                this.setState({
                  newShift: newS
                });
              } else if(o.target.value !== "" && o.target.valueAsNumber < 0) {
                confirmAlert({
                  title: "Errore",
                  message: "Valore minimo: 0 (inizio turno)",
                  buttons: [
                    {
                      label: "OK"
                    }
                  ]
                });
              } else if(o.target.value !== "" && o.target.valueAsNumber > 99) {
                confirmAlert({
                  title: "Errore",
                  message: "Valore massimo: 99",
                  buttons: [
                    {
                      label: "OK"
                    }
                  ]
                });
              }
            }} /> giorni prima
          </p>
        </div> }

        { (s.event !== undefined || s.customEvent !== undefined) && <div>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassStart } />
            <input type="date" onChange={o => {
              let newS = s;
              newS.startDate = o.target.value;
              if(newS.startDate !== undefined && newS.startTime !== undefined) {
                newS.start = s.startDate + " " + s.startTime;
              }
              this.setState({
                newShift: newS,
                mergeShifts: this.state.shifts.filter(s2 => {
                  if(
                    (
                      (newS.start !== undefined && new Date(newS.start.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime() && new Date(newS.start.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime()) ||
                      (newS.end !== undefined && new Date(newS.end.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime() && new Date(newS.end.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime())
                    ) && (
                      (newS.team !== undefined && newS.team === s2.team) ||
                      (newS.team === undefined && s2.team === undefined && newS.activity === s2.activity)
                    )
                  ) return true;
                  return false;
                })
              })
            }} />
            <input type="time" value={ s.startTime } onChange={o => {
              let newS = s;
              newS.startTime = o.target.value;
              if(newS.startDate !== undefined && newS.startTime !== undefined) {
                newS.start = s.startDate + " " + s.startTime;
              }
              this.setState({
                newShift: newS,
                mergeShifts: this.state.shifts.filter(s2 => {
                  if(
                    (
                      (newS.start !== undefined && new Date(newS.start.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime() && new Date(newS.start.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime()) ||
                      (newS.end !== undefined && new Date(newS.end.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime() && new Date(newS.end.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime())
                    ) && (
                      (newS.team !== undefined && newS.team === s2.team) ||
                      (newS.team === undefined && s2.team === undefined && newS.activity === s2.activity)
                    )
                  ) return true;
                  return false;
                })
              })
            }} />
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faHourglassEnd } />
            <input type="date" onChange={o => {
              let newS = s;
              newS.endDate = o.target.value;
              if(newS.endDate !== undefined && newS.endTime !== undefined) {
                newS.end = s.endDate + " " + s.endTime;
              }
              this.setState({
                newShift: newS,
                mergeShifts: this.state.shifts.filter(s2 => {
                  if(
                    (
                      (newS.start !== undefined && new Date(newS.start.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime() && new Date(newS.start.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime()) ||
                      (newS.end !== undefined && new Date(newS.end.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime() && new Date(newS.end.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime())
                    ) && (
                      (newS.team !== undefined && newS.team === s2.team) ||
                      (newS.team === undefined && s2.team === undefined && newS.activity === s2.activity)
                    )
                  ) return true;
                  return false;
                })
              })
            }} />
            <input type="time" value={ s.endTime } onChange={o => {
              let newS = s;
              newS.endTime = o.target.value;
              if(newS.endDate !== undefined && newS.endTime !== undefined) {
                newS.end = s.endDate + " " + s.endTime;
              }
              this.setState({
                newShift: newS,
                mergeShifts: this.state.shifts.filter(s2 => {
                  if(
                    (
                      (newS.start !== undefined && new Date(newS.start.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime() && new Date(newS.start.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime()) ||
                      (newS.end !== undefined && new Date(newS.end.replace(" ", "T")).getTime() <= new Date(s2.end.replace(" ", "T")).getTime() && new Date(newS.end.replace(" ", "T")).getTime() >= new Date(s2.start.replace(" ", "T")).getTime())
                    ) && (
                      (newS.team !== undefined && newS.team === s2.team) ||
                      (newS.team === undefined && s2.team === undefined && newS.activity === s2.activity)
                    )
                  ) return true;
                  return false;
                })
              })
            }} />
          </p>
        </div> }

        { this.state.mergeShifts.length > 0 && <div style={{ marginTop: "20px" }}>
          <p><u>Turni che puoi unire al nuovo:</u></p>

          <ul>
            { this.state.mergeShifts.map(sm => {
              return <li>
                <p>
                  { sm.activityName + (sm.team !== null ? ": "+sm.teamName : "") }<br />
                  {new Date(sm.start.replace(" ", "T")).toLocaleString()} - {new Date(sm.end.replace(" ", "T")).toLocaleString()}
                </p>
                <button onClick={() => confirmAlert({
                  title: "Conferma",
                  message: "Quale deve essere il nome del turno unificato?",
                  buttons: [
                    {
                      label: "Annulla"
                    },
                    this.state.newShift.event !== null && {
                      label: "\"" + this.state.newShift.eventName + "\"",
                      onClick: () => {
                        const newS = this.state.newShift;
                        const modS = sm;

                        modS.eventName = newS.eventName;
                        modS.start = new Date(newS.start.replace(" ", "T")).getTime() <= new Date(modS.start.replace(" ", "T")).getTime() ? newS.start : modS.start;
                        modS.end = new Date(newS.end.replace(" ", "T")).getTime() >= new Date(modS.end.replace(" ", "T")).getTime() ? newS.end : modS.end;
                        
                        modS.equipes = modS.equipes.concat({
                          shift: modS.n,
                          name: newS.eventName,
                          start: newS.start,
                          end: newS.end,
                          pat5: null,
                          pat4: null,
                          cs: null,
                          socc1: null,
                          socc2: null,
                          socc3: null,
                          vol1: null,
                          vol2: null,
                          vol3: null,
                          osr: null,
                          info: null,
                          help: null,
                        });

                        this.setShift(modS)
                        .then(() => this.getShifts())
                        .then(() => this.setState({
                          newShift: null,
                          mergeShifts: [],
                          shift: modS
                        }))
                        .then(() => this.setEquipes());
                      }
                    },
                    {
                      label: "\""+sm.eventName+"\"",
                      onClick: () => {
                        const newS = this.state.newShift;
                        const modS = sm;

                        modS.start = new Date(newS.start.replace(" ", "T")).getTime() <= new Date(modS.start.replace(" ", "T")).getTime() ? newS.start : modS.start;
                        modS.end = new Date(newS.end.replace(" ", "T")).getTime() >= new Date(modS.end.replace(" ", "T")).getTime() ? newS.end : modS.end;

                        modS.equipes = modS.equipes.concat({
                          shift: modS.n,
                          name: newS.eventName,
                          start: newS.start,
                          end: newS.end,
                          pat5: null,
                          pat4: null,
                          cs: null,
                          socc1: null,
                          socc2: null,
                          socc3: null,
                          vol1: null,
                          vol2: null,
                          vol3: null,
                          osr: null,
                          info: null,
                          help: null,
                        });

                        this.setShift(modS)
                        .then(() => this.getShifts())
                        .then(() => this.setState({
                          newShift: null,
                          mergeShifts: [],
                          shift: modS
                        }))
                        .then(() => this.setEquipes());
                      }
                    }
                  ]
                })} title="Unisci il nuovo turno con quello esistente"><FontAwesomeIcon className="fa-fw" icon={ faCodeBranch } /></button>
              </li>
            }) }
          </ul>
        </div> }

        { (s.event !== undefined || s.customEvent !== undefined) &&
        s.start !== undefined && s.end !== undefined &&
        <button className="action" onClick={() => {
          let newS = s;

          if(new Date(newS.end.replace(" ", "T")).getTime() <= new Date(newS.start.replace(" ", "T")).getTime()) {
            confirmAlert({
              title: "Errore",
              message: "L'orario di fine turno deve essere superiore a quello di inizio",
              buttons: [
                {
                  label: "OK"
                }
              ]
            });
          } else {
            this.setShift(newS).then(() => this.getShifts().then(() => this.setState({
              newShift: null,
              mergeShifts: []
            })));
          }
        }} title={"Crea evento"+(this.state.mergeShifts.length > 0 ? " indipendente" : "")}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button> }

      </div>
    )
  }

  getShifts() {
    return fetch('https://api.cristinaweb.rossini.dev/get_shifts.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.user.id,
        mode: this.state.pastShifts ? "past" : "future",
      }),
    })
    .then((response) => {
      response.json().then(responseJson => {
        if(response.status === 200) {
          let newS = null;
          if(this.state.shift !== null) {
            
            const oldS = this.state.shifts.filter(s => s.n === this.state.shift.n)[0];
            newS = responseJson.filter(s => s.n === this.state.shift.n)[0];
            
            newS.equipes = oldS.equipes;
  
            if(this.state.avail !== null) {
      
              newS.avails = newS.avails.map(a => {
                if(a.id === this.state.avail.id)
                  return this.state.avail;
                return a;
              });
            }
          }
  
          this.setState({
            shifts: responseJson,
            shift: newS,
          });
  
          let eNames = [];
          responseJson.forEach(s => s.equipes.forEach(e => {
            if(e.name !== null && e.name !== "" && !eNames.some(e2 => e2 === e.name.toString())){
              eNames = eNames.concat(e.name.toString());
            }
          }));
  
          this.setState({
            equipesNames: eNames,
          });
        } else {
          this.setState({
            shifts: [],
          });

          confirmAlert({
            title: "Errore",
            message: JSON.stringify(responseJson),
            buttons: [
              {
                label: "OK"
              }
            ]
          });
        }
      })
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  getShift(n) {
    return fetch('https://api.cristinaweb.rossini.dev/get_shift.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.user.id,
        n: n,
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {

      this.setState({
        shifts: responseJson
      })
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setShift(s) {
    return fetch('https://api.cristinaweb.rossini.dev/set_shift.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shift: s
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Impossibile salvare: Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setEquipes() {
    return fetch('https://api.cristinaweb.rossini.dev/set_equipes.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        equipes: this.state.shift.equipes
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        let newS = this.state.shift;
        newS.equipes = responseJson.equipes;

        this.setState({
          shift: newS
        });
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Impossibile salvare: Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setAvail() {
    return fetch('https://api.cristinaweb.rossini.dev/set_avail.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        avail: this.state.avail
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Impossibile salvare: Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  delShift() {
    return fetch('https://api.cristinaweb.rossini.dev/del_shift.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        shift: this.state.shift
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

}

export default Turni;