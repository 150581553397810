import React from 'react';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faBackspace, faPaperPlane, faPlus, faUndoAlt, faTimes, faEnvelope, faCopy } from '@fortawesome/free-solid-svg-icons';

import './Email.css';

class Email extends React.Component {
    
  constructor(props) {
    super(props);

    this.state = {
      groups: [],
      addGroups: undefined,
      roles: [],
    }
  }

  componentDidMount() {

    let roles = this.state.roles;
    this.props.users.forEach(u => {
      u.roles.forEach(nr => {
        if(roles.every(r => r !== nr)) {
          roles = roles.concat(nr);
        }
      });
    });

    this.setState({
      roles: roles,
    })
  }

  render() {

    if(this.state.addGroups !== undefined) {

      return(
        this.addGroups()
      )
    } else {

      return(
        this.newEmail()
      )
    }
  }

  newEmail() {

    const users = this.props.users.filter(u => u.id !== this.props.user.id && this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val))));

    return(
      <div id="email">
        <button onClick={() => window.open("http://webmail.crisandonato.org", "_blank")} style={{ marginTop: "20px" }}><FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />Accedi alle Email CRISDO</button>

        { users.length > 0 && <button className="action" onClick={() => window.open("mailto:"+users.map(u => u.email), "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faPaperPlane } title="Scrivi email con i destinatari selezionati" /></button> }
        { users.length > 0 && <CopyToClipboard text={ users.map(u => u.email) } onCopy={() => confirmAlert({
          title: "Email copiate",
          message: "e pronte per essere incollate",
          buttons: [
            {
              label: "Chiudi"
            }
          ]
        })} options={{ format: "text/plain" }}>
          <button className="action2" title="Copia le email dei destinatari"><FontAwesomeIcon className="fa-fw" icon={ faCopy } /></button>
        </CopyToClipboard> }

        <h1>Nuova Email</h1>

        <div className="groups">
          <h4> Gruppi destinatari:</h4>

          { this.state.groups.length === 0 ? <p>L'unione dei gruppi personalizzati che aggiungi</p> : <ul>
            { this.state.groups.map((g, i) => {
              return <li key={i}>
                { g.map((g2, i2) => {
                  if(i2 === g.length-1)
                    return g2.name;
                  return [g2.name, <FontAwesomeIcon className="fa-fw" icon={ faTimes } title="Intersezione" style={{ cursor: "context-menu" }} />];
                }) }
                { i !== this.state.groups.length-1 ? [<br />, <FontAwesomeIcon className="fa-fw" icon={ faPlus } title="Somma" style={{ cursor: "context-menu" }} />] : "" }
              </li>
            }) }
          </ul> }
        </div>
        
        <div style={{ display: "flex", flexDirection: "row" }}>
          { this.state.groups.length > 0 && <button onClick={() => {
            let newGroups = this.state.groups;

            newGroups.pop();
            this.setState({
              groups: newGroups,
            })
          }}><FontAwesomeIcon className="fa-fw" icon={ faBackspace } title="Elimina l'ultimo gruppo" /></button> }

          <button onClick={() => this.setState({
            addGroups: []
          })} title="Aggiungi gruppo personalizzato" ><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button>
        </div>

        <div className="users">
          <h4>{ this.props.users.filter(u => u.id !== this.props.user.id && this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val)))).length } utenti selezionati:</h4>
          
          <ul>
            { this.props.users.filter(u => u.id !== this.props.user.id && this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val)))).map((u, i) => {
              return(
                <li key={i}>
                  { u.name }
                </li>
              )
            }) }
          </ul>
        </div>
      </div>
    )
  }

  isUserInGroup(u, gval) {
    return "u:"+u.id === gval ||
      u.subs.some(s => s === gval) ||
      u.roles.some(r => gval === "r:"+r ||
        (gval === "r:Patente 4" && r === "Patente 5") ||
        (gval === "r:Soccorritore" && (r === "Patente 5" || r === "CS" || r === "Tutor" || r === "Istruttore TSSE")) ||
        (gval === "r:CS" && r === "Istruttore TSSE") ||
        (gval === "r:Tutor" && r === "Istruttore TSSE"))
  }

  addGroups() {

    let addGroups = this.state.addGroups !== undefined ? this.state.addGroups : [];
    const newUsers = addGroups.length > 0 ? this.props.users.filter(u => u.id !== this.props.user.id && !this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val))) && addGroups.every(g => this.isUserInGroup(u, g.val))) : [];

    return(
      <div id="add-groups">
        <button className="back" onClick={() => this.setState({
          addGroups: undefined
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        { addGroups.length > 0 && <button className="action" onClick={() => {
          let newGroups = this.state.groups;
          newGroups.push(addGroups);
          this.setState({
            groups: newGroups,
            addGroups: undefined
          })
        }}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button> }

        <h1>Aggiungi destinatari</h1>

        <div className="groups">
          <h4>Nuovo gruppo:</h4>

          { addGroups.length === 0 ? <p>L'intersezione dei gruppi che selezioni</p> : <ul>
            { addGroups.map((g, gi) => {
              return <li>
                { g.name }
                { gi !== addGroups.length-1 && [<br />, <FontAwesomeIcon className="fa-fw" icon={ faTimes } title="Intersezione" style={{ cursor: "context-menu" }} />] }
              </li>
            }) }
          </ul> }
        
          { addGroups.length > 0 && <button onClick={() => {
            let newAddGroups = addGroups;
            newAddGroups.pop();
            this.setState({
              addGroups: newAddGroups
            })
          }} title="Elimina l'ultimo gruppo"><FontAwesomeIcon className="fa-fw" icon={ faBackspace } /></button> }
        
          { addGroups.length > 0 && newUsers.length === 0 && <p>L'intersezione dei gruppi selezionati non individua alcun nuovo utente; potresti aver già inserito tutti gli utenti, o aver ristretto troppo il gruppo con le intersezioni.</p> }
        </div>

        { this.props.activities.filter(a => ((a.youreAdmin === 1 || a.youreMod === 1) && this.state.groups.every(g => "a:"+a.n !== g.val) && addGroups.every(g => "a:"+a.n !== g.val)) || this.props.teams.some(t => t.youreAdmin === 1 || t.youreMod === 1)).length > 0 && <select value="" onChange={o => {
          let newAddGroups = addGroups;
          if(o.target.value !== "") {
            
            newAddGroups = newAddGroups.concat({
              val: "a:"+this.props.activities.filter(a => a.n.toString() === o.target.value)[0].n,
              name: this.props.activities.filter(a => a.n.toString() === o.target.value)[0].name
            });

            this.setState({
              addGroups: newAddGroups,
            });
          }
        }}>
          <option key={-1} value="">-- Aggiungi attività destinataria --</option>
          { this.props.activities
            .filter(a => ((a.youreAdmin === 1 || a.youreMod === 1) && this.state.groups.every(g => "a:"+a.n !== g.val) && addGroups.every(g => "a:"+a.n !== g.val)) || this.props.teams.some(t => t.youreAdmin === 1 || t.youreMod === 1))
            .map((a, i) => {
              return(
                <option key={i} value={a.n}>{ a.name }</option>
              )
            })
          }
        </select> }

        { this.props.teams.filter(t => (this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) || (t.youreAdmin === 1 || t.youreMod === 1)) && this.state.groups.every(g => "t:"+t.n !== g.val) && addGroups.every(g => "t:"+t.n !== g.val)).length > 0 && <select value="" onChange={o => {
          let newAddGroups = addGroups;
          if(o.target.value !== "") {
            
            newAddGroups = newAddGroups.concat({
              val: "t:"+this.props.teams.filter(t => t.n.toString() === o.target.value)[0].n,
              name: this.props.teams.filter(t => t.n.toString() === o.target.value)[0].name
            });

            this.setState({
              addGroups: newAddGroups
            });
          }
        }}>
          <option key={-1} value="">-- Aggiungi squadra destinataria --</option>
          { this.props.teams
            .filter(t => (this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) || (t.youreAdmin === 1 || t.youreMod === 1)) && this.state.groups.every(g => "t:"+t.n !== g.val) && addGroups.every(g => "t:"+t.n !== g.val))
            .map((t, i) => {
              return(
                <option key={i} value={t.n}>{ t.name }</option>
              )
            })
          }
        </select> }

        { this.state.roles
          .filter(r => {
            if(addGroups.some(g => g.val === "r:Soccorritore") && (r === "Istruttore TSSE" || r === "Tutor" || r === "CS" || r === "Patente 5" || r === "TSS"))
              return false;
            if(addGroups.some(g => g.val === "r:CS") && (r === "Soccorritore" || r === "TSS"))
              return false;
            if(addGroups.some(g => g.val === "r:Istruttore TSSE") && (r === "Tutor" || r === "CS" || r === "Soccorritore" || r === "TSS"))
              return false;
            if(addGroups.some(g => g.val === "r:Patente 5") && r === "Patente 4")
              return false;
            if(addGroups.some(g => g.val === "r:"+r))
              return false;
            return true;
          })
          .length > 0 && <select value="" onChange={o => {

          let newAddGroups = addGroups;
          if(o.target.value !== "") {
            
            if(o.target.value === "Soccorritore") {
              newAddGroups = newAddGroups.filter(g => g.val !== "r:Istruttore TSSE" && g.val !== "r:Tutor" && g.val !== "r:CS" && g.val !== "r:Patente 5" && g.val !== "r:TSS");

              newAddGroups = newAddGroups.concat({
                val: "r:"+o.target.value,
                name: o.target.value+" (include Istruttori TSSE, Tutor, CS, TSS)"
              });
            } else if(o.target.value === "CS") {
              newAddGroups = newAddGroups.filter(g => g.val !== "r:Soccorritore" && g.val !== "r:TSS");

              newAddGroups = newAddGroups.concat({
                val: "r:"+o.target.value,
                name: o.target.value+" (include Soccorritori, TSS)"
              });
            } else if(o.target.value === "Istruttore TSSE") {
              newAddGroups = newAddGroups.filter(g => g.val !== "r:Tutor" && g.val !== "r:CS" && g.val !== "r:Soccorritore" && g.val !== "r:TSS");

              newAddGroups = newAddGroups.concat({
                val: "r:"+o.target.value,
                name: o.target.value+" (include Tutor, CS, Soccorritori, TSS)"
              });
            } else if(o.target.value === "Patente 5") {
              newAddGroups = newAddGroups.filter(g => g.val !== "r:Patente 4");

              newAddGroups = newAddGroups.concat({
                val: "r:"+o.target.value,
                name: o.target.value+" (include Patenti 4)"
              });
            } else {
              newAddGroups = newAddGroups.concat({
                val: "r:"+o.target.value,
                name: o.target.value
              });
            }
            
            this.setState({
              addGroups: newAddGroups
            });
          }
        }}>
          <option key={-1} value="">-- Aggiungi ruolo destinatario --</option>
          { this.state.roles
            .filter(r => {
              if(addGroups.some(g => g.val === "r:Soccorritore") && (r === "Istruttore TSSE" || r === "Tutor" || r === "CS" || r === "Patente 5" || r === "TSS"))
                return false;
              if(addGroups.some(g => g.val === "r:CS") && (r === "Soccorritore" || r === "TSS"))
                return false;
              if(addGroups.some(g => g.val === "r:Istruttore TSSE") && (r === "Tutor" || r === "CS" || r === "Soccorritore" || r === "TSS"))
                return false;
              if(addGroups.some(g => g.val === "r:Patente 5") && r === "Patente 4")
                return false;
              if(addGroups.some(g => g.val === "r:"+r))
                return false;
              return true;
            })
            .map((r, i) => {
              return(
                <option key={i} value={r}>{ r }</option>
              )
            })
          }
        </select> }

        { this.props.users
        .filter(u => u.id !== this.props.user.id && !this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val))) && (addGroups.length === 0 || addGroups.every(g => this.isUserInGroup(u, g.val)))).length > 0 &&
        <select value="" onChange={o => {
          let newAddGroups = addGroups;
          if(o.target.value !== "") {

            const newU = this.props.users.filter(u => u.id.toString() === o.target.value)[0];
            
            newAddGroups = [{
              val: "u:"+newU.id,
              name: newU.name
            }];

            let newGroups = this.state.groups;
            newGroups.push(newAddGroups);
            
            this.setState({
              groups: newGroups,
              addGroups: undefined
            })
          }
        }}>
          <option key={-1} value="">-- Aggiungi utente destinatario --</option>
          { this.props.users
            .filter(u => u.id !== this.props.user.id && !this.state.groups.some(g => g.every(g2 => this.isUserInGroup(u, g2.val))) && (addGroups.length === 0 || addGroups.every(g => this.isUserInGroup(u, g.val))))
            .map((u, i) => {
              return(
                <option key={i} value={u.id}>{ u.name }</option>
              )
            })
          }
        </select> }

        <div className="users">
          <h4>Stai aggiungendo { newUsers.length } nuovi utenti:</h4>
          
          <ul>
            { newUsers.map((u, i) => {
              return(
                <li key={i}>
                  { u.name }
                </li>
              )
            }) }
          </ul>
        </div>
      </div>
    )
  }
}

export default Email;