import React from 'react';

import { GoogleLogin } from 'react-google-login';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import './Login.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faExclamationTriangle, faUserSecret } from '@fortawesome/free-solid-svg-icons';

class Login extends React.Component {
    
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);

    this.state = {
      emailChoice: "",
      pinChoice: "",
      isPin: false,
      isReset: false,
      isRegister: false,
      logging: false,
    }
  }

  componentDidMount() {

    if(localStorage.getItem("login") !== null) {
      const login = JSON.parse(localStorage.getItem("login"));
      
      this.login(login.user.email, login.user.googleId, login.user.name);
    }
  }

  render() {
    return(
      <div id="login">
        <h1>Accedi</h1>

        <div style={{ marginTop: "10vh" }}>
          
          { this.state.logging && <p><FontAwesomeIcon className="fa-fw fa-spin" icon={ faCircleNotch } /></p> }
          
          <GoogleLogin
            clientId="745711243649-32q6tdlr9jqqm0hssnl3jpdnoblh38he.apps.googleusercontent.com"
            buttonText="Accedi con crisandonato.org"
            onSuccess={r => {
              const gUser = r.profileObj;

              this.login(gUser.email, gUser.googleId, gUser.name);
            }}
            onFailure={r => confirmAlert({
              title: "Errore",
              message: r.details !== null && r.details !== undefined ? r.details.slice(0, 100) + "[...]" : "",
              buttons: [
                {
                  label: "OK"
                }
              ]
            })}
            cookiePolicy={'single_host_origin'}
            theme="dark"
          />

          {/* eslint-disable-next-line */}
          <a clickable="true" style={{ marginTop: "20px" }} onClick={() => confirmAlert({
            title: "",
            message: "Aggiungi un account @crisandonato.org al tuo dispositivo cliccando sul pulsante \"Prosegui\", poi riprova l'accesso con l'apposito pulsante \"Accedi\"",
            buttons: [
              {
                label: "Annulla"
              },
              {
                label: "Prosegui",
                onClick: () => window.open("https://accounts.google.com/SignOutOptions?hl=it", "_blank")
              }
            ]
          })}>
            <FontAwesomeIcon className="fa-fw" icon={ faExclamationTriangle } />
            Non riesco ad accedere
          </a>

          <a href="/privacy.html" target="blank" style={{ marginTop: "15vh" }}>
            <FontAwesomeIcon className="fa-fw" icon={ faUserSecret } />
            Informativa sulla Privacy
          </a>
          
        </div>
      </div>
    )
  }

  login(e, g, n) {

    this.setState({
      logging: true
    })

    fetch('https://api.cristinaweb.rossini.dev/login.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: e,
        gid: g,
        name: n,
      }),
    })
    .then((response) => {
      response.json().then(responseJson => {
        console.log(responseJson)

        if(response.status === 200) {
          responseJson.user.googleId = g;
          localStorage.setItem("login", JSON.stringify(responseJson));

          if(responseJson.user.dark === 1) {
            document.getElementsByTagName("html")[0].setAttribute("dark", "true");
          }
          if(responseJson.user.theme !== null) {
            document.getElementsByTagName("html")[0].setAttribute("theme", responseJson.user.theme);
          }

          this.props.login(responseJson);
        } else {

          this.setState({
            logging: false
          })

          confirmAlert({
            title: "Errore",
            message: responseJson.info,
            buttons: [
              {
                label: "OK"
              }
            ]
          });
        }
      })
    })
    .catch((error) => {
      if(error.toString().indexOf("Failed to fetch") !== -1) {
        this.offlineLogin();
      } else {
        console.error(error)
        confirmAlert({
          title: "Errore",
          message: error.toString(),
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    });
  }

  offlineLogin() {
    if(localStorage.getItem("login") !== null) {
      let loginData = JSON.parse(localStorage.getItem("login"));
      loginData.user.offline = true;

      confirmAlert({
        title: "Attenzione",
        message: "Sei offline, i dati caricati risalgono al "+new Date(loginData.user.login.replace(" ", "T")).toLocaleString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });
      
      this.props.login(loginData);
    } else {
      confirmAlert({
        title: "Errore",
        message: "Sei offline, e non sono disponibili dati precedentemente scaricati",
        buttons: [
          {
            label: "OK"
          }
        ]
      });
    }
  }
}

export default Login;