import React from 'react';

import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import 'react-confirm-alert/src/react-confirm-alert.css';

import Login from "./components/Login";
import Home from "./components/Home";
import Turni from "./components/Turni";
import Email from "./components/Email";
import Contatti from "./components/Contatti";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faCalendarAlt, faEnvelope, faAddressBook } from '@fortawesome/free-solid-svg-icons'

import logo from './logo512.png';
import './App-main.css';
import './App-theme.css';
import { isMobile } from 'react-device-detect';

class App extends React.Component {

  /* eslint-disable-next-line */
  constructor(props) {
    super(props);

    this.state = {
      user: null,
      users: [],
      activities: [],
      teams: [],
      coms: [],
      comsLimit: null,
    }
  }

  render() {

    return (
      <div id="app">
        <div id="header">
          <img src={ logo } alt="logo" />
          <div>
            <h1>Cristina</h1><h2>Web</h2>
          </div>
        </div>
        <div id="body">
          { this.state.user !== null ? <Router basename="/#">
            <Switch>
              <Route path="/turni">
                <Turni user={ this.state.user } users={ this.state.users } activities={ this.state.activities } teams={ this.state.teams } />
              </Route>
              <Route path="/email">
                <Email user={ this.state.user } users={ this.state.users } activities={ this.state.activities } teams={ this.state.teams } />
              </Route>
              <Route path="/contatti">
                <Contatti user={ this.state.user } users={ this.state.users } activities={ this.state.activities } teams={ this.state.teams } />
              </Route>
              <Route path="/">
                <Home logout={() => {
                  localStorage.removeItem("login");
                  this.setState({
                    user: null
                  });
                }} loginRetry={data => this.setState({
                  user: data.user,
                  users: data.users,
                  activities: data.activities,
                  teams: data.teams
                })} user={ this.state.user } users={ this.state.users } activities={ this.state.activities } teams={ this.state.teams } />
              </Route>
            </Switch>
            <div id="router">
              <Link to="/"><FontAwesomeIcon className="fa-fw" icon={ faHome } />{ ! isMobile ? "Home" : "" }</Link>
              <Link to="/turni"><FontAwesomeIcon className="fa-fw" icon={ faCalendarAlt } />{ ! isMobile ? "Turni" : "" }</Link>
              <Link to="/email"><FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />{ ! isMobile ? "Email" : "" }</Link>
              <Link to="/contatti"><FontAwesomeIcon className="fa-fw" icon={ faAddressBook } />{ ! isMobile ? "Contatti" : "" }</Link>
            </div>
          </Router> : <Login login={data => this.setState({
            user: data.user,
            users: data.users,
            activities: data.activities,
            teams: data.teams
          })} /> }
        </div>
      </div>
    )
  }
}

export default App;
