import React from 'react';

import { isMobile, isAndroid, isIOS, isWinPhone } from 'react-device-detect';
import CryptoJS from 'crypto-js/core';
import AES from 'crypto-js/aes';

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faUser, faArchive, faAmbulance, faEnvelope, faLocationArrow, faUndoAlt, faHeartbeat, faTasks, faCrown, faHandsHelping, faUserTie, faHome, faPhone, faTimes, faSignOutAlt, faCar, faChalkboardTeacher, faUserTimes, faUsers, faCheck, faKey, faUserAstronaut, faBandAid, faUserGraduate, faUserFriends, faPlus, faEye, faTrash, faHospitalSymbol, faSearchLocation, faShareAlt, faMapMarked, faSitemap, faUserNurse, faInfoCircle, faCogs, faHistory, faCircleNotch, faStar, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { faWhatsapp, faGoogleDrive, faTelegram } from '@fortawesome/free-brands-svg-icons';

import './Home.css';

class Home extends React.Component {
  
  /* eslint-disable-next-line */
  constructor(props) {
    super(props);

    this.state = {
      profile: null,
      route: null,
      portachiavi: false,
      activity: null,
      activityUser: null,
      team: null,
      user: null,
      naviLocation: "",
      naviStreet: "",
      naviNumber: "",
      naviCross: "",
      naviFav: false,
      isChrono: false,
      chronoStart: null,
      chronoPart: [],
      shifts: null,
      chronoStamp: "00:00:00",
      chronoInt: null,
      setEventTime: null,
      loginRetryTime: null
    }
  }

  componentDidMount() {

    this.setState({
      user: this.props.user,
      loginRetryTime: this.props.user.offline ? setTimeout(() => this.loginRetry(), 10000) : null
    });

    this.getShifts();

    // if(localStorage.getItem("crono") !== null) {
    //   const crono = JSON.parse(localStorage.getItem("crono"));

    //   this.setState({
    //     chronoStart: crono.start,
    //     chronoPart: crono.part,
    //     chronoInt: setInterval(() => this.setChronoStamp(), 1000),
    //   });
    // }

    /*history.pushState(null, null, location.href);
    window.onpopstate = () => {
      if(this.state.route !== null) {
        this.setState({
          route: null
        })
      } else if(this.state.activity !== null) {
        this.setState({
          activity: null
        })
      } else if(this.state.team !== null) {
        this.setState({
          team: null
        })
      } else if(this.state.portachiavi) {
        this.setState({
          portachiavi: false
        })
      }
    }*/
  }

  componentWillUnmount() {

    if(this.state.chronoInt !== null) {
      clearInterval(this.state.chronoInt);
    }
  }

  render() {
    
    if(this.state.profile !== null) {
      return this.profile()
    } else if(this.state.activity !== null) {
      if(this.state.activityUser !== null) {
        return this.viewActivityManageUser()
      } else {
        return this.viewActivity()
      }
    } else if(this.state.team !== null) {
      return this.viewTeam()
    } else if(this.state.portachiavi) {
      return this.portachiavi()
    } else {
      return this.router()
    }
  }

  portachiavi() {
    return(
      <div id="portachiavi">
        <button className="back" onClick={() => this.setState({
          portachiavi: false
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>Portachiavi</h1>

        <p>* Causa emergenza COVID-19 tutti gli accessi devono essere autorizzati dal personale addetto!</p>
        <ul>
          <li>
            <p className="text">Tablet AREU:</p>
            <p className="code">123</p>
          </li>
          <li>
            <p className="text">Stazionam. Vizzolo:</p>
            <p className="code">1623</p>
          </li>
          <li>
            <p className="text">H San Donato:<br />(PS-&gt;astant)</p>
            {/* <p className="code">6311</p> */}
            <p className="code"><i>Nascosto *</i></p>
          </li>
          <li>
            <p className="text">H San Donato:<br />(reparti-&gt;astant)</p>
            {/* <p className="code"><FontAwesomeIcon icon={ faKey } />+0631</p> */}
            <p className="code"><i>Nascosto *</i></p>
          </li>
          <li>
            <p className="text">H Melegnano:<br />(reparti-&gt;PS)</p>
            {/* <p className="code">11</p> */}
            <p className="code"><i>Nascosto *</i></p>
          </li>
          <li>
            <p className="text">H Poli:<br />(accett-&gt;area verde/gialla)</p>
            {/* <p className="code">1969</p> */}
            <p className="code"><i>Nascosto *</i></p>
          </li>
          { this.state.user.keys.map((k, ki) => {
            return(
              <li key={ki}>
                <p className="text">
                  <FontAwesomeIcon className="fa-fw" icon={ faUser } title="Chiave personale segreta" />
                  { k.name }:
                </p>
                <p className="code">
                  <div style={{ display: "flex", flexDirection: "row" }}>
                    <button onClick={() => confirmAlert({
                      title: k.name,
                      message: AES.decrypt(k.value, "CristinaWebSecret1234").toString(CryptoJS.enc.Utf8),
                      buttons: [
                        {
                          label: "Chiudi"
                        }
                      ]
                    })}><FontAwesomeIcon className="fa-fw" icon={ faEye } /></button>
                    <button onClick={() => confirmAlert({
                      title: "Attenzione",
                      message: "Eliminare la chiave "+k.name+"?",
                      buttons: [
                        {
                          label: "Annulla"
                        },
                        {
                          label: "Si, elimina",
                          onClick: () => this.delKey(k.n)
                        }
                      ]
                    })}><FontAwesomeIcon className="fa-fw" icon={ faTrash } /></button>
                  </div>
                </p>
              </li>
            )
          }) }
          { this.state.newKey === undefined ? <li>
            <button onClick={() => this.setState({
              newKey: {
                name: null,
                value: null
              }
            })} title="Le password vengono cifrate con algoritmo AES in modo che solo tu possa leggerle quando lo richiedi!">
              <FontAwesomeIcon className="fa-fw" icon={ faPlus } />
              Aggiungi chiave personale
            </button>
          </li> : <li>
            <p>
              <input type="text" value={ this.state.newKey.name === null ? "" : this.state.newKey.name } onChange={o => {
                let newNewKey = this.state.newKey;
                newNewKey.name = o.target.value !== "" ? o.target.value : null;
                
                this.setState({
                  newKey: newNewKey
                })
              }} placeholder="Nome chiave" />
              <input type="text" value={ this.state.newKey.value === null ? "" : this.state.newKey.value } onChange={o => {
                let newNewKey = this.state.newKey;
                newNewKey.value = o.target.value !== "" ? o.target.value : null;
                
                this.setState({
                  newKey: newNewKey
                })
              }} placeholder="Password" />
            </p>
            { this.state.newKey.name !== null && this.state.newKey.value !== null &&
            <button onClick={() => {
              this.setKey({
                name: this.state.newKey.name,
                value: AES.encrypt(this.state.newKey.value, "CristinaWebSecret1234").toString()
              });
            }}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button> }
          </li> }
        </ul>
      </div>
    )
  }

  share() {
    return(
      <div id="share">
        <p>
          <b>CRI S</b>anDonato <b>T</b>eams <b>I</b>mproved <b>N</b>etwork <b>A</b>ccess<br />
          <i>By Andrea Rossini - Istruttore AREU</i>
        </p>
        <div>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faShareAlt } size="2x" title="Condividi con" style={{ cursor: "context-menu" }} />
          </p>
          <button onClick={() => {
            const tool = "mailto:";
            const site = "https://cristinaweb.rossini.dev";
            window.open(tool + "?subject=CristinaWeb&body=0ADai%20un%27occhiata%21%0A%0ABy Andrea Rossini" + site, "_blank");
          }} title="Condividi tramite Email">
            <FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />
          </button>
          <button onClick={() => {
            const tool = "https://t.me/share/url";
            const site = "https://cristinaweb.rossini.dev";
            window.open(tool + "?url=" + site + "&text=CristinaWeb%20-%20By%20Andrea%20Rossini%0A%0ADai%20un%27occhiata%21", "_blank");
          }} title="Condividi con Telegram">
            <FontAwesomeIcon className="fa-fw" icon={ faTelegram } />
          </button>
          <button onClick={() => {
            const tool = isMobile ? "whatsapp://send" : "https://web.whatsapp.com/send";
            const site = "https://cristinaweb.rossini.dev";
            window.open(tool + "?text=Dai%20un%27occhiata%21%0A%0ACristinaWeb%20-%20By%20Andrea%20Rossini%0A" + site, "_blank");
          }} title="Condividi con Whatsapp">
            <FontAwesomeIcon className="fa-fw" icon={ faWhatsapp } />
          </button>
        </div>
      </div>
    )
  }

  profile() {
    const p = this.state.profile;

    let date2Limit = new Date();
    date2Limit.setDate(date2Limit.getDate() - (31*2));

    let date6Limit = new Date();
    date6Limit.setDate(date2Limit.getDate() - (30*6));

    const pastAvails = this.state.shifts === null ? [] : this.state.shifts.filter(s => new Date(s.start) >= date6Limit && new Date(s.start) < new Date() && s.avails.some(a => a.avail === 1 && a.user === p.id));

    return(
      <div id="profilo">
        <button className="back" onClick={() => this.setState({
          profile: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>{ p.name }</h1>

        <p>
          <FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />
          { p.email }
        </p>

        <h3>Informazioni</h3>
        <ul>
          { (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod" || this.props.activities.some(a => a.youreAdmin === 1 || a.youreMod === 1) || this.props.teams.some(t => t.youreAdmin === 1 || t.youreMod === 1)) && <li>
            <p>Nascita: </p>
            <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input type="date" value={ p.birth } onChange={o => {
              let newU = p;
              newU.birth = o.target.value;
              newU.modif = true;
              this.setState({
                user: newU
              })
            }} placeholder="Data di Nascita" /> : new Date(p.birth).toLocaleDateString() }</p>
          </li> }
          <li>
            <p>Telefono: </p>
            <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input type="tel" value={ p.phone } onChange={o => {
              let newU = p;
              newU.phone = o.target.value  !== "" ? o.target.value.replace(/[^0-9]+/g, "") : null;
              newU.modif = true;
              this.setState({
                user: newU
              })
            }} placeholder="Telefono" /> : p.phone }</p>
          </li>
          <li>
            <p>Accesso in CRI: </p>
            <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input type="date" value={ p.access } onChange={o => {
              let newU = p;
              newU.access = o.target.value;
              newU.modif = true;
              this.setState({
                user: newU
              })
            }} placeholder="Data di Accesso" /> : new Date(p.access).toLocaleDateString() }</p>
          </li>
          <li>
            <p>Certific. SSE: </p>
            <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input type="date" value={ p.cert } onChange={o => {
              let newU = p;
              newU.cert = o.target.value !== "" ? o.target.value : null;
              newU.modif = true;
              this.setState({
                user: newU
              })
            }} placeholder="Data di Certif. TSSE" /> : new Date(p.cert).toLocaleDateString() }</p>
          </li>
          <li>
            <p>Ultimo BLSD: </p>
            <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input type="date" value={ p.blsd } onChange={o => {
              let newU = p;
              newU.blsd = o.target.value !== "" ? o.target.value : null;
              newU.modif = true;
              this.setState({
                user: newU
              })
            }} placeholder="Data BLSD o Refresh" /> : new Date(p.blsd).toLocaleDateString() }</p>
          </li>
          { p.data !== undefined ? p.data.map((d, di) => {
            return <li key={di}>
              <p>{ d.name }: </p>
              <p>{ (p.id === this.props.user.id || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <input value={ d.value !== null ? d.value : "" } onChange={e => {
                const newP = p;

                const newD = d;
                newD.value = e.target.value !== "" ? e.target.value : null;

                newP.data.map(d2 => {
                  if(d2.n === d.n)
                    return d;
                  return d2;
                });

                newP.modif = true;
                this.setState({
                  profile: newP
                })
              }} placeholder="Valore" /> : d.value }</p>
            </li>
          }) : <p><FontAwesomeIcon className="fa-fw fa-spin" icon={ faCircleNotch } /></p> }
          { this.state.newData !== undefined ? <li key={1}>
            <input value={ this.state.newData.name } onChange={e => {
              const newNewData = this.state.newData;
              newNewData.name = e.target.value;

              this.setState({
                newData: newNewData
              });
            }} placeholder="Nome" />
            <input onChange={e => {
              const newNewData = this.state.newData;
              newNewData.value = e.target.value;

              this.setState({
                newData: newNewData
              });
            }} placeholder="Valore" />
            { this.state.newData.name !== "" && this.state.newData.value !== "" && <button onClick={() => this.setData(this.state.newData)}>
              <FontAwesomeIcon icon={ faCheck } />
            </button> }
          </li> : <li key={1}>
            <button onClick={() => this.setState({
              newData: {
                user: p.id,
                name: "",
                value: ""
              }
            })}><FontAwesomeIcon className="fa-fw" icon={ faPlus } />Aggiungi valore personalizzato</button>
          </li> }
        </ul>
        
        { p.modif !== undefined && <button onClick={() => this.setProfile(p)}>
          <FontAwesomeIcon className="fa-fw" icon={ faCheck } />Conferma
        </button> }

        <h3>Ruoli</h3>
        <p>
          { p.roles.length > 0 ? p.roles.map((r, i) => {
            /*switch(r) {
              case "Istruttore TSSE":
                r += "(include CS, Tutor, Soccorritore)";
                break;
              case "Patente 5":
                r += "(include Patente 4, Soccorritore)";
                break;
            }*/
            if(i !== p.roles.length-1)
              return r + ", ";
            return r;
          }) : "Nessun ruolo registrato" }
        </p>

        { p.id === this.props.user.id && [
          <h3 key={0}>Impostazioni</h3>,
          <ul key={1}>
            <li>
              <p>Modalità scura: </p>
              <input type="checkbox" checked={ p.dark === 1 } onChange={e => {
                const newP = p;
                if(newP.dark === 0) {
                  newP.dark = 1;
                  document.getElementsByTagName("html")[0].setAttribute("dark", "true");
                } else {
                  newP.dark = 0;
                  document.getElementsByTagName("html")[0].removeAttribute("dark");
                }

                this.setState({
                  profile: newP
                });
                this.setProfile(newP);
              }} />
            </li>
            <li>
              <p>Tema: </p>
              <select value={ p.theme !== null ? p.theme : "" } onChange={e => {
                const newP = p;
                if(e.target.value === "") {
                  newP.theme = null;
                  document.getElementsByTagName("html")[0].removeAttribute("theme");
                } else {
                  newP.theme = e.target.value;
                  document.getElementsByTagName("html")[0].setAttribute("theme", e.target.value);
                }

                this.setState({
                  profile: newP
                });
                this.setProfile(newP);
              }}>
                <option value="">Predefinito</option>
                <option value="pink">Rosa</option>
              </select>
            </li>
          </ul>
        ] }

        <h3>Ultime disponibilità</h3>
        { this.props.user.offline ? <p>Non disponibile offline</p> : <div>
          { pastAvails.length > 0 ? <ol reversed style={{ marginTop: 0 }}>
            { pastAvails.map((s, si) => {
              const paActivity = this.props.activities.filter(a => a.n === s.activity)[0];
              const paEvent = paActivity !== undefined ? paActivity.events.filter(e => e.n === s.event)[0] : undefined;
              const paTeam = this.props.teams.filter(t => t.n === s.team)[0];
              return <li key={si} style={{ display: "list-item" }}>
                <p>
                  <b>{ (paActivity !== undefined ? paActivity.name+": " : "") + (paEvent !== undefined ? paEvent.name+" " : "") + (paTeam !== undefined ? paTeam.name : "") }</b><br />
                  { new Date(s.avails.filter(a => a.user === p.id)[0].start).toLocaleString() + " - " + new Date(s.avails.filter(a => a.user === p.id)[0].end).toLocaleString() }
                </p>
              </li>
            }) }
          </ol> : <p>Nessuna</p> }
        </div> }
      </div>
    )
  }

  router() {

    if(this.state.route === null) {

      return (
        <div>
          <div id="home-router">
            <h2>
              Ciao, {this.props.user.name.split(" ")[0]}!
              <button style={{ borderColor: "red" }} onClick={ this.props.logout } title="Logout"><FontAwesomeIcon className="fa-fw" icon={ faSignOutAlt } /></button>
            </h2>

            <div>
              {/* eslint-disable-next-line */}
              <a onClick={() => this.setState({
                profile: this.props.user
              })}>
                <FontAwesomeIcon className="fa-fw" icon={ faUser } size="3x" />
                <br />
                <FontAwesomeIcon className="fa-fw" icon={ faInfoCircle } />
                <FontAwesomeIcon className="fa-fw" icon={ faCogs } />
                <FontAwesomeIcon className="fa-fw" icon={ faHistory } />
                <br />
                Profilo
              </a>
              {/* eslint-disable-next-line */}
              <a onClick={() => this.setState({
                route: "iscrizioni"
              })}>
                <FontAwesomeIcon className="fa-fw" icon={ faUsers } size="3x" />
                <br />
                <FontAwesomeIcon className="fa-fw" icon={ faUserTie } />
                <FontAwesomeIcon className="fa-fw" icon={ faCrown } />
                <FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } />
                <br />
                Iscrizioni
              </a>
            </div>
            <div>
              {/* eslint-disable-next-line */}
              <a onClick={() => this.setState({
                route: "navigatore"
              })}>
                <FontAwesomeIcon className="fa-fw" icon={ faLocationArrow } size="3x" />
                <br />
                <FontAwesomeIcon className="fa-fw" icon={ faSearchLocation } />
                <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
                <FontAwesomeIcon className="fa-fw" icon={ faHome } />
                <br />
                Navigatore
              </a>
              {/* eslint-disable-next-line */}
              <a onClick={() => this.setState({
                route: "materiale"
              })}>
                <FontAwesomeIcon className="fa-fw" icon={ faArchive } size="3x" />
                <br />
                <FontAwesomeIcon className="fa-fw" icon={ faTasks } />
                <FontAwesomeIcon className="fa-fw" icon={ faKey } />
                <FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />
                <br />
                Materiale
              </a>
            </div>
          </div>

          { this.share() }
        </div>
      )
    } else if(this.state.route === "iscrizioni") {

      return(
        <div id="iscrizioni">
          <button className="back" onClick={() => this.setState({
            route: null
          })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
          <h1>Iscrizioni</h1>

          <div className="home-container">
            { this.props.activities
              .filter(a => a.youreSub === 1 && a.youreAdmin !== 1 && a.youreMod !== 1)
              .map((a, i) => {
                return(
                  <button key={i} onClick={() => this.setState({
                    activity: a
                  })}>
                    { a.name }
                  </button>
                )
              })
            }
            { this.props.teams
              .filter(t => t.youreSub === 1 && t.youreAdmin !== 1 && t.youreMod !== 1)
              .map((t, i) => {
                return(
                  <button key={i} onClick={() => this.setState({
                    team: t
                  })}>
                    { t.name }
                  </button>
                )
              })
            }
          </div>

          { this.props.activities.filter(a => a.youreAdmin === 1 || a.youreMod === 1).length > 0 &&
            <h3>Amministrazione attività</h3>
          }
          <div className="home-container">
            { this.props.activities
              .filter(a => a.youreAdmin === 1 || a.youreMod === 1)
              .map((a, i) => {
                return(
                  <button key={i} onClick={() => this.setState({
                    activity: a
                  })}>
                    { a.name }
                  </button>
                )
              })
            }
          </div>

          { this.props.teams.filter(t => t.youreAdmin === 1 || t.youreMod === 1).length > 0 &&
            <h3>Amministrazione squadre</h3>
          }
          <div className="home-container">
            { this.props.teams
              .filter(t => t.youreAdmin === 1)
              .map((t, i) => {
                return(
                  <button key={i} onClick={() => this.setState({
                    team: t
                  })}>
                    { t.name }
                  </button>
                )
              })
            }
            { this.props.teams
              .filter(t => t.youreMod === 1 && t.youreAdmin !== 1)
              .map((t, i) => {
                return(
                  <button key={i} onClick={() => this.setState({
                    team: t
                  })}>
                    <FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } title="Aiutante" style={{ cursor: "context-menu" }} />
                    { t.name }
                  </button>
                )
              })
            }
          </div>

        </div>
      )
    } else if(this.state.route === "navigatore") {

      return(
        <div id="navigatore">
          <button className="back" onClick={() => this.setState({
            route: null
          })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
          <h1>Navigatore</h1>

          { isMobile && <button onClick={() => {
            window.open("google.navigation:/?free=1&mode=d&entry=fnls", "_blank");
          }}><FontAwesomeIcon className="fa-fw" icon={ faMapMarked } />Posizione attuale</button> }
  
          <h2>Cerca destinazione</h2>
  
          <datalist id='locations'>
            <option value='San Donato Milanese'>San Donato Milanese</option>
              <option value='Poasco'>Poasco (San Donato Milanese)</option>
            <option value='Milano'>Milano</option>
              <option value='Chiaravalle'>Chiaravalle (Milano)</option>
            <option value='Opera'>Opera</option>
              <option value='Noverasco'>Noverasco (Opera)</option>
            <option value='San Giuliano Milanese'>San Giuliano Milanese</option>
              <option value='Civesio'>Civesio (San Giuliano Milanese)</option>
              <option value='Sesto Ulteriano'>Sesto Ulteriano (San Giuliano Milanese)</option>
              <option value='Mezzano'>Mezzano (San Giuliano Milanese)</option>
              <option value='Pedriano'>Pedriano (San Giuliano Milanese)</option>
            <option value='Melegnano'>Melegnano</option>
            <option value='Vizzolo Predabissi'>Vizzolo Predabissi</option>
            <option value='Peschiera Borromeo'>Peschiera Borromeo</option>
              <option value='Bettola'>Bettola (Peschiera Borromeo)</option>
            <option value='Dresano'>Dresano</option>
            <option value='Colturano'>Colturano</option>
            <option value='Cerro al Lambro'>Cerro al Lambro</option>
          </datalist>
  
          <form onSubmit={e => {
            e.preventDefault();
  
            const a = encodeURIComponent(this.state.naviStreet) + 
              (this.state.naviNumber !== "" ? ("%20"+this.state.naviNumber) : "") + 
              (this.state.naviCross !== "" ? ("%26%20"+encodeURIComponent(this.state.naviCross)) : "") +
              ("%2C%20"+encodeURIComponent(this.state.naviLocation))
            this.navi(a);

            if(this.state.naviFav) {
              this.setNaviFav(a);
            }
          }}>
  
            <input type="text" list="locations" value={ this.state.naviLocation } onChange={e => this.setState({
              naviLocation: e.target.value
            })} placeholder="Località" />
  
            { this.state.naviLocation !== "" && <input type="text" value={ this.state.naviStreet } onChange={e => this.setState({
              naviStreet: e.target.value
            })} placeholder="Via / Piazza / Luogo / ..." /> }
  
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", flexWrap: "wrap" }}>
              { this.state.naviLocation !== "" && this.state.naviStreet !== "" && this.state.naviCross === "" && <input type="number" min={1} value={ this.state.naviNumber } onChange={e => this.setState({
                naviNumber: e.target.value
              })} placeholder="Civico" /> }
              { this.state.naviLocation !== "" && this.state.naviStreet !== "" && this.state.naviNumber === "" && this.state.naviCross === "" && <p style={{ margin: "10px 5px 0px" }}>oppure</p> }
              { this.state.naviLocation !== "" && this.state.naviStreet !== "" && this.state.naviNumber === "" && <input type="text" value={ this.state.naviCross } onChange={e => this.setState({
                naviCross: e.target.value
              })} placeholder="Incrocio Via" /> }
            </div>

            { this.state.naviLocation !== "" && this.state.naviStreet !== "" && [
              <p key={0}>Aggiungi destinazione ai preferiti: <input type="checkbox" checked={ this.state.naviFav } onChange={ e => this.setState({
                naviFav: ! this.state.naviFav
              }) } /></p>,
              <button key={1} type="submit"><FontAwesomeIcon className="fa-fw" icon={ faSearchLocation } />Cerca</button>
            ] }
          </form>
  
          <h2>Destinazioni Predefinite</h2>

          <h3>Le tue destinazioni</h3>
          <ul>
            { this.state.user.navi.map((l, li) => {
              return <li key={li}>
                <button className="name ricorr" onClick={() => this.navi(l.search)} title={ l.search.length > 20 ? decodeURIComponent(l.search) : "" }>
                  <FontAwesomeIcon className="fa-fw" icon={ faStar } />
                  { decodeURIComponent(l.search).length <= 20 ? decodeURIComponent(l.search) : decodeURIComponent(l.search).substr(0, 20)+"[...]" }
                </button>
                <button className="del" onClick={() => this.delNaviFav(l)}><FontAwesomeIcon className="fa-fw" icon={ faTrash } /></button>
              </li>
            }) }
          </ul>
          <p style={{ marginTop: 0 }}>Per aggiungere destinazioni personalizzate, spunta l'apposita casella durante la ricerca del luogo.</p>
          
          <h3>San Donato</h3>
          <button className="ricorr" onClick={() => this.navi('45.411077,9.277540')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Donato
          </button>
          <button className="ricorr" onClick={() => this.navi('45.412127,9.270623')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHome } />
            Sede
          </button>
          
          <h3>Vizzolo Predabissi</h3>
          <button className="ricorr" onClick={() => this.navi('45.359446,9.339887')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Melegnano
          </button>
          <button className="ricorr" onClick={() => this.navi('45.360995,9.346486')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHome } />
            Stazionamento Vizzolo
          </button>
          
          <h3>Milano</h3>
          <button className="ricorr" onClick={() => this.navi('45.459541,9.196198')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Policlinico
          </button>
          <button onClick={() => this.navi('45.486313,9.166757')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Buzzi
          </button>
          <button onClick={() => this.navi('45.444395,9.261560')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Cardiologico Monzino
          </button>
          <button onClick={() => this.navi('45.484947,9.225439')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Città Studi
          </button>
          <button onClick={() => this.navi('45.458481,9.198895')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            De Marchi
          </button>
          <button onClick={() => this.navi('45.4790304,9.1904333')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Fatebenefratelli
          </button>
          <button onClick={() => this.navi('45.4795346,9.191688')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Fatebenefratelli Oftalmico
          </button>
          <button onClick={() => this.navi('45.523309,9.173572')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Galeazzi Ortopedico
          </button>
          <button onClick={() => this.navi('45.466678,9.219529')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Macedonio Melloni
          </button>
          <button onClick={() => this.navi('45.458800,9.198839')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Mangiagalli
          </button>
          <button onClick={() => this.navi('45.513148,9.184764')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Niguarda
          </button>
          <button onClick={() => this.navi('45.454092,9.193916')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Pini G.
          </button>
          <button onClick={() => this.navi('45.518677,9.120652')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Sacco
          </button>
          <button onClick={() => this.navi('45.484726,9.153320')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Sant'Ambrogio
          </button>
          <button onClick={() => this.navi('45.469712,9.112813')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Carlo
          </button>
          <button onClick={() => this.navi('45.462927,9.171736')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Giuseppe
          </button>
          <button onClick={() => this.navi('45.472001,9.145646')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Luca Auxologico
          </button>
          <button onClick={() => this.navi('45.433370,9.161741')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Paolo
          </button>
          <button onClick={() => this.navi('45.525840,9.214060')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Traumatologico Ortopedico
          </button>

          <h3>Segrate</h3>
          <button onClick={() => this.navi('45.503911,9.265723')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            San Raffaele
          </button>
          
          <h3>Rozzano</h3>
          <button onClick={() => this.navi('45.375407,9.165130')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Humanitas
          </button>
          
          <h3>Cernusco Sul Naviglio</h3>
          <button onClick={() => this.navi('45.523239,9.328181')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Cernusco Uboldo
          </button>

          <h3>Melzo</h3>
          <button onClick={() => this.navi('45.508233,9.426238')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Melzo
          </button>
          
          <h3>Sesto San Giovanni</h3>
          <button onClick={() => this.navi('45.536675,9.225433')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Sesto Citta'
          </button>
          
          <h3>Lodi</h3>
          <button onClick={() => this.navi('45.315062,9.511153')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Lodi Osp. Magg.
          </button>
          
          <h3>Monza</h3>
          <button onClick={() => this.navi('45.584238,9.297133')}>
            <FontAwesomeIcon className="fa-fw" icon={ faHospitalSymbol } />
            Monza Policlinico
          </button> 
        </div> 
      )
      
      /*if(this.state.isChrono) {

        return(
          <div id="cronometro">
             <button className="back" onClick={() => this.setState({
              isChrono: false
            })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
            <h1>Cronometro</h1>

            <p className="display">{ this.state.chronoStamp }</p>

            { this.state.chronoInt !== null ? <p>
              Avviato<FontAwesomeIcon className="fa-fw fa-pulse" icon={ faSpinner } />
            </p> : <p>
              In attesa
            </p> }

            <div>

              <div className="partials">

                <h3>Parziali:</h3>
                { this.state.chronoPart.map(p => {
                  return <p>{ p }</p>
                }) }
              </div>
              <div className="controls">

                <button onClick={() => {
                  if(this.state.chronoInt === null) {

                    const newStart = new Date().getTime();

                    this.setState({
                      chronoStamp: "00:00:00",
                      chronoInt: setInterval(() => this.setChronoStamp(), 1000),
                      chronoStart: newStart,
                      chronoPart: [],
                    });
                    cookie.save("crono", {
                      start: newStart,
                      part: [],
                    }, {
                      path: ".",
                      expires: new Date(Date.now()+1000*3600*24),
                      maxAge: 3600*24,
                    });
                  } else {

                    confirmAlert({
                      title: "Attenzione",
                      message: "Una volta fermato non sarà possibile riprendere, confermi?",
                      buttons: [
                        {
                          label: "No"
                        },
                        {
                          label: "Si, fermalo",
                          onClick: () => {
                            clearInterval(this.state.chronoInt);
                            this.setState({
                              chronoInt: null,
                            })

                            cookie.remove("crono", {
                              path: "."
                            });
                          }
                        }
                      ]
                    });
                  }
                }} title="Play / Stop"><FontAwesomeIcon className="fa-fw" size="2x" icon={ this.state.chronoInt === null ? faPlay : faStop } /></button>

                { this.state.chronoInt !== null && <button onClick={() => {

                  const newPart = this.state.chronoPart.concat(this.state.chronoStamp);

                  this.setState({
                    chronoPart: newPart,
                  });

                  cookie.save("crono", {
                    start: this.state.chronoStart,
                    part: newPart,
                  }, {
                    path: ".",
                    expires: new Date(Date.now()+1000*3600*24)
                  });
                }} title="Annota parziale"><FontAwesomeIcon className="fa-fw" size="2x" icon={ faStopwatch } /></button> }
              </div>
            </div>
            
          </div>
        )
      } else {

        return(
          <div id="servizi">
            <button className="back" onClick={() => this.setState({
              route: null
            })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
            <h1>Servizi</h1>
  
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
              { isMobile && <button onClick={() => {
                window.open("google.navigation:/?free=1&mode=d&entry=fnls", "_blank");
              }} title="Mostra la posizione attuale"><FontAwesomeIcon className="fa-fw" icon={ faMapMarked } /></button> }
              <button onClick={() => this.setState({
                isNavi: true
              })}><FontAwesomeIcon className="fa-fw" icon={ faLocationArrow } />Naviga</button>
            </div>

            <h3>Valutazione</h3>
            <div className="home-container">
              { isMobile && <button onClick={() => this.setState({
                isChrono: true
              })}><FontAwesomeIcon className="fa-fw" icon={ faStopwatch } />Cronometro{ this.state.chronoInt !== null ? <FontAwesomeIcon className="fa-fw fa-pulse" icon={ faSpinner } /> : "" }</button> }
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <button onClick={() => window.open( "https://drive.google.com/file/d/1N6-b8RCBAJ6_2riZDEiFCrwX1kXAz87_", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />BLSD / Disostruzione</button>
                <button onClick={() => window.open( "https://drive.google.com/open?id=1HIVg-1RD5ctvk5OP0w8DsfihP5O9SR4Z", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />ECG / Parametri</button>
              </div>            
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center" }}>
                <button onClick={() => window.open("https://drive.google.com/file/d/1TPNvbteg22OTletp6BQitYa6pUTNSaZ4", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faBaby } />FLACC pain scale<br />(&lt; 3 anni)</button>
                <button onClick={() => window.open("https://drive.google.com/file/d/13QR5cMK9PX_eFSJYhNSfEQuA95_Uwv_y", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faChild } />Faces pain scale<br />(&gt; 3 anni)</button>
              </div>
              <button onClick={() => window.open("https://drive.google.com/file/d/0B_mbCbba40sVbl9Dd2ZQNldJTkU", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faFire } />Ustioni</button>
            </div>

            <h3>Assistenti</h3>
            <div className="home-container">
              <button onClick={() => {
              if(isIOS)
                window.open("https://itunes.apple.com/it/app/mediately-farmaci/id1107600948?ls=1&ct=mediately_web&mt=8", "_blank");
              else if(isAndroid)
                window.open("https://play.google.com/store/apps/details?id=com.mediately.drugs.it&launch=true", "_blank");
              else
                window.open("https://mediately.co/it/drugs", "_blank");
              }}><FontAwesomeIcon className="fa-fw" icon={ faBookMedical } />Farmacologia</button>
              <button onClick={() => window.open("https://drive.google.com/file/d/1pvKzidoDdOZu6vTXsVxuf7gLK9Y7y7_A", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faBabyCarriage } />Trasporto Pediatrico</button>
              <button onClick={() => window.open("https://drive.google.com/file/d/1PERmCSyeDqt4OqzqPZVtSY929baPhgor", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />Nascita</button>
              <button onClick={() => window.open( isAndroid ? "https://play.google.com/store/apps/details?id=it.freelysoft.adrcode&launch=true" : "http://www.vigilfuoco.it/aspx/page.aspx?IdPage=3653", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faBiohazard } />Kemler/ONU</button>
            </div>
          </div>
        )*/
    } else if(this.state.route === "materiale") {
      
      return(
        <div>
          <button className="back" onClick={() => this.setState({
            route: null
          })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
          <h1>Materiale</h1>

          <button onClick={() => window.open("https://goo.gl/forms/bIspiqqA3AtKBClk1", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faTasks } />CheckList Mezzi</button>
          <button onClick={() => window.open("https://docs.google.com/forms/d/e/1FAIpQLScmZA_ih_F0lCrTz4DoHq5S05Kh-ly-1ArTeT5luxwbF6t6Vg/viewform", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faExclamation } />Segnalazione Mezzi</button>

          <button onClick={() => this.setState({
            portachiavi: true
          })}><FontAwesomeIcon className="fa-fw" icon={ faKey } />Portachiavi</button>

          <button onClick={() => window.open("https://drive.google.com/file/d/1ZNoeXfdWaVQz0SD9S_ELf8EQYXZIAZh0/view?usp=sharing", "_blank")} ><FontAwesomeIcon className="fa-fw" icon={ faSitemap } />Organigramma</button>

          <button onClick={() => this.setState({
            route: "iscrizioni"
          })} style={{ marginTop: "20px" }}><FontAwesomeIcon className="fa-fw" icon={ faUsers } />Materiale di attività/squadra</button>

          <button onClick={() => window.open("https://www.areu.lombardia.it/web/home/mappa-dae-lombardia", "_blank")} style={{ marginTop: "40px" }} ><FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />Mappa DAE</button>
          <button style={{ borderColor: "red" }} onClick={() => {
            if(isIOS)
              window.open("https://itunes.apple.com/it/app/112-where-are-u/id888964800?ls=1&mt=8", "_blank");
            else if(isAndroid)
              window.open("https://play.google.com/store/apps/details?id=it.Beta80Group.whereareu&launch=true", "_blank");
            else if(isWinPhone)
              window.open("https://www.microsoft.com/it-it/store/apps/112-where-are-u/9wzdncrdbr89", "_blank");
            else
              window.open("https://where.areu.lombardia.it", "_blank"); 
          }}><FontAwesomeIcon className="fa-fw" icon={ faPhone } />Where ARE U</button>
          
          {/* <div className="home-container">
            <button onClick={() => window.open("https://drive.google.com/open?id=1FDbNDqgjSa1tO8pudbbD7zLq_6GNYHwA", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faHandPointRight } />IOP e PRO standard</button>
            <button onClick={() => window.open("https://drive.google.com/open?id=1tLSP1ZWaYeBkwrjMjB2dd49OifAlw0Ru", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faBiohazard } />Emergenza COVID-19</button>
            <p>
              <button onClick={() => window.open("https://drive.google.com/open?id=1MX9x7XnTgLuQflHPWu3wV7t1eYHlNwEe", "_blank")} style={{ marginRight: "0px" }}><FontAwesomeIcon className="fa-fw" icon={ faArchive } />Tutto il materiale SSE</button>
              <button onClick={() => {
                const tool = "mailto:";
                const site = "https://drive.google.com/open?id=1MX9x7XnTgLuQflHPWu3wV7t1eYHlNwEe";
                window.open(tool + "?subject=Materiale SSE&body=Dai un'occhiata!%0A%0A" + site, "_blank");
              }} title="Condividi per Email" style={{ marginLeft: "0px", marginRight: "0px" }}>
                <FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />
              </button>
              <button onClick={() => {
                const tool = isMobile ? "whatsapp://send" : "https://web.whatsapp.com/send";
                const site = "https://drive.google.com/open?id=1MX9x7XnTgLuQflHPWu3wV7t1eYHlNwEe";
                window.open(tool + "?text=Dai un'occhiata!%0A%0A" + site, "_blank");
              }} title="Condividi con Whatsapp" style={{ marginLeft: "0px" }}>
                <FontAwesomeIcon className="fa-fw" icon={ faWhatsapp } />
              </button>
            </p>
          </div>

          <h3>Strumenti</h3>
          <div className="home-container">
            <button onClick={() => window.open("https://goo.gl/forms/bIspiqqA3AtKBClk1", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faTasks } />CheckList Mezzi</button>
            <button onClick={() => this.setState({
              portachiavi: true
            })}><FontAwesomeIcon className="fa-fw" icon={ faKey } />Portachiavi</button>
            <p>
              <button onClick={() => window.open("https://t.me/joinchat/DUvDRBsO7ueZbQSTnFB50A", "_blank")} style={{ marginRight: "0px" }}><FontAwesomeIcon className="fa-fw" icon={ faComments } />Q&amp;A e Discussioni SSE</button>
              <button onClick={() => {
                const tool = "mailto:";
                const site = "https://t.me/joinchat/DUvDRBsO7ueZbQSTnFB50A";
                window.open(tool + "?subject="+encodeURIComponent("Q&A e Discussioni SSE - Telegram")+"&body=Dai un'occhiata!%0A%0A" + site, "_blank");
              }} title="Condividi per Email" style={{ marginLeft: "0px", marginRight: "0px" }}>
                <FontAwesomeIcon className="fa-fw" icon={ faEnvelope } />
              </button>
              <button onClick={() => {
                const tool = isMobile ? "whatsapp://send" : "https://web.whatsapp.com/send";
                const site = "https://t.me/joinchat/DUvDRBsO7ueZbQSTnFB50A";
                window.open(tool + "?text=Dai un'occhiata!%0A%0A" + site, "_blank");
              }} title="Condividi con Whatsapp" style={{ marginLeft: "0px" }}>
                <FontAwesomeIcon className="fa-fw" icon={ faWhatsapp } />
              </button>
            </p>
          </div>
          
          <h3>Emergenza</h3>
          <div className="home-container">
            <button onClick={() => window.open("https://www.areu.lombardia.it/web/home/mappa-dae-lombardia", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faHeartbeat } />Mappa DAE</button>
            <button style={{ borderColor: "red" }} onClick={() => {
              if(isIOS)
                window.open("https://itunes.apple.com/it/app/112-where-are-u/id888964800?ls=1&mt=8", "_blank");
              else if(isAndroid)
                window.open("https://play.google.com/store/apps/details?id=it.Beta80Group.whereareu&launch=true", "_blank");
              else if(isWinPhone)
                window.open("https://www.microsoft.com/it-it/store/apps/112-where-are-u/9wzdncrdbr89", "_blank");
              else
                window.open("https://where.areu.lombardia.it", "_blank"); 
            }}><FontAwesomeIcon className="fa-fw" icon={ faPhone } />Where ARE U</button>
          </div> */}

        </div>
      )
    }
  }

  navi(address) {
    window.open('https://www.google.com/maps/search/?api=1&query=' + address, "_blank");
  }

  viewActivity() {

    const a = this.state.activity;

    return(
      <div id="view-activity">
        <button className="back" onClick={() => this.setState({
          activity: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>{ a.name }</h1>

        <h3>Responsabili di Attività</h3>
          <ul className="managers">

          { a.users.filter(u => u.isAdmin).map((u, i) => {
            return(
              <li key={i}>
                <u>{ u.name }</u>
              </li>
            )
          }) }
          { a.users.filter(u => u.isMod).map((u, i) => {
            return(
              <li key={i}>
                <FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } title="Aiutante" style={{ cursor: "context-menu" }} />
                { u.name }
              </li>
            )
          }) }
        </ul>

        { (a.links.length > 0 || a.youreAdmin !== 0 || a.youreMod !== 0) && <div className="links">
          <h3>Collegamenti</h3>

          <ul style={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", justifyContent: "center" }}>
            { a.links.map((l, i) => {
              return(
                <li key={i}>
                  <button onClick={() => {
                    if(a.youreAdmin !== 0 || a.youreMod !== 0) {
                      confirmAlert({
                        title: "Amministrazione",
                        message: "Cosa vuoi fare?",
                        buttons: [
                          {
                            label: "Annulla"
                          },
                          {
                            label: "Modifica",
                            onClick: () => {
                              let newA = a;

                              newA.newLink = l;

                              this.setState({
                                activity: newA
                              })
                            }
                          },
                          {
                            label: "Apri",
                            onClick: () => window.open(l.url, "_blank")
                          }
                        ]
                      });
                    } else {
                      window.open(l.url, "_blank");
                    }
                  }} title={ l.url }>
                    { l.name }
                  </button>
                </li>
              )
            }) }
            { (a.youreAdmin !== 0 || a.youreMod !== 0) && <button active={ a.newLink !== undefined ? "true" : "" } onClick={() => {
              let newA = a;

              if(newA.newLink === undefined) {
                newA.newLink = {
                  name: "",
                  url: ""
                }
              } else {
                delete newA.newLink;
              }

              this.setState({
                activity: newA
              })
            }} title="Aggiungi collegamento"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
          </ul>
          { a.newLink !== undefined && <div>
            <input type="text" value={ a.newLink.name } onChange={o => {
              let newA = a;

              newA.newLink.name = o.target.value;
              this.setState({
                activity: newA
              })
            }} placeholder="Nome" />
            <span>
              <input type="url" value={ a.newLink.url } onChange={o => {
                let newA = a;

                newA.newLink.url = o.target.value;
                this.setState({
                  activity: newA
                })
              }} placeholder="URL" />
              <button onClick={() => window.open("https://drive.google.com/", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faGoogleDrive } title="Apri Google Drive" /></button>
            </span>
            <span>
              { a.newLink.n !== undefined && a.newLink.name !== "" && a.newLink.url !== "" && <button onClick={() => {
                let newA = a;
                newA.links = newA.links.filter(l2 => l2.n !== a.newLink.n);

                this.delLink(a.newLink.n);
                delete a.newLink;
                this.setActivity(newA);

                this.setState({
                  activity: newA
                })
              }}><FontAwesomeIcon className="fa-fw" icon={ faTrash } /></button> }
              { a.newLink.name !== "" && a.newLink.url !== "" && <button onClick={() => {
                let newA = a;
                let newL = newA.newLink;
                this.setLink(newL).then(n => {
                  newL.n = n;

                  if(newA.links.some(l => l.n === n)) {
                    newA.links = newA.links.map(l => {
                      if(l.n === n)
                        return newL;
                      return l;
                    });
                  } else {
                    newA.links = newA.links.concat(newL);
                  }
                  delete newA.newLink;

                  this.setState({
                    activity: newA
                  })
                  this.setActivity(newA);
                });
              }}><FontAwesomeIcon className="fa-fw" icon={ faCheck } />Salva</button> }
            </span>
          </div> }
        </div> }

        <h3>Eventi</h3>
        <ul id="eventi">

          { a.events.map((e, i) => {
            return(
              <li key={i}>
                <h4>{e.name}</h4>
                { (a.youreAdmin === 1 || a.youreMod === 1) ? <p>
                  <input type="time" value={ e.defaultStart !== null ? e.defaultStart : "" } onChange={o => {
                    if(this.state.setEventTime !== null)
                      clearTimeout(this.state.setEventTime);
                    
                    let newE = e;
                    newE.defaultStart = o.target.value;
                    let newA = a;
                    newA.events = newA.events.map(e2 => {
                      if(e2.n === e.n)
                        return newE;
                      return e2;
                    });
                    this.setState({
                      activity: newA,
                      setEventTime: setTimeout(() => this.setEvent(newE), 1000)
                    });
                  }} />
                  <input type="time" value={ e.defaultEnd !== null ? e.defaultEnd : "" } onChange={o => {
                    if(this.state.setEventTime !== null)
                      clearTimeout(this.state.setEventTime);

                    let newE = e;
                    newE.defaultEnd = o.target.value;
                    let newA = a;
                    newA.events = newA.events.map(e2 => {
                      if(e2.n === e.n)
                        return newE;
                      return e2;
                    });
                    this.setState({
                      activity: newA,
                      setEventTime: setTimeout(() => this.setEvent(newE), 1000)
                    });
                  }} />
                  { (e.defaultStart !== null || e.defaultEnd !== null) && <button onClick={() => {
                    if(this.state.setEventTime !== null)
                      clearTimeout(this.state.setEventTime);

                    let newE = e;
                    newE.defaultStart = null;
                    newE.defaultEnd = null;
                    let newA = a;
                    newA.events = newA.events.map(e2 => {
                      if(e2.n === e.n)
                        return newE;
                      return e2;
                    });
                    this.setState({
                      activity: newA
                    });
                    
                    this.setEvent(newE);
                  }} title="Rimuovi l'orario predefinito"><FontAwesomeIcon className="fa-fw" icon={ faTimes } /></button> }
                </p> : <p>
                  {e.defaultStart !== null ? e.defaultStart.substr(0, 5) : "?"} - {e.defaultEnd !== null ? e.defaultEnd.substr(0, 5) : "?"}
                </p> }
                <p>
                  Chiusura raccolta: { (a.youreAdmin === 1 || a.youreMod === 1) ? <input type="number" min={0} max={99} value={ e.defaultLockDays } onChange={o => {
                    if(this.state.setEventTime !== null)
                      clearTimeout(this.state.setEventTime);

                    if(o.target.value !== "" && o.target.valueAsNumber >= 0 && o.target.valueAsNumber <= 99) {
                      let newE = e;
                      newE.defaultLockDays = o.target.value;
                      let newA = a;
                      newA.events = newA.events.map(e2 => {
                        if(e2.n === e.n)
                          return newE;
                        return e2;
                      });
                      this.setState({
                        activity: newA,
                        setEventTime: setTimeout(() => this.setEvent(newE), 1000)
                      });
                    } else if(o.target.value !== "" && o.target.valueAsNumber < 0) {
                      confirmAlert({
                        title: "Errore",
                        message: "Valore minimo: 0 (turno iniziato)",
                        buttons: [
                          {
                            label: "OK"
                          }
                        ]
                      });
                    } else if(o.target.value !== "" && o.target.valueAsNumber > 99) {
                      confirmAlert({
                        title: "Errore",
                        message: "Valore massimo: 99",
                        buttons: [
                          {
                            label: "OK"
                          }
                        ]
                      });
                    }
                  }} /> : e.defaultLockDays } giorni prima
                </p>
              </li>
            )
          }) }
        </ul>
        { a.youreAdmin === 1 ? <p>Per aggiungere o rimuovere gli eventi, contattare <a href={
          "mailto:"+(this.props.users.filter(u => u.rank === "owner" || u.rank === "admin")).map(u => u.email)+
          "?subject="+encodeURIComponent("[CRIsdoGEST] Modifica Eventi attività")+
          "&body="+encodeURIComponent("In merito all'attività "+a.name+", vorrei aggiungere/rimuovere ...\n\n\n"+this.props.user.name+
            (this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).map(a => "\nResponsabile "+a.name) : "")+
            (this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).map(a => "\nCollaboratore "+a.name) : "")+
            (this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).map(t => "\nResponsabile "+t.name) : "")+
            (this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).map(t => "\nCollaboratore "+t.name) : "")+
            (this.props.user.rank === "owner" || this.props.user.rank === "admin" ? "\nAmministratore di sistema" : "")+
            (this.props.user.rank === "mod" ? "\nCollaboratore di sistema" : "")
          )
        } target="_blank" rel="noopener noreferrer">un amministratore</a></p> : "" }

        <h3>Utenti</h3>
        <ul id="users">

          { (a.youreAdmin === 1 || a.youreMod === 1) && <li key={-1}>
            <p className="name">
              <select value="" onChange={o => {
                
                let newU = this.props.users.filter(u => u.id.toString() === o.target.value)[0];
                newU.subs = newU.subs.concat('a:'+a.n);
                
                newU.isSub = 1;
                newU.isAdmin = 0;
                newU.isMod = 0;

                this.setUser(newU);
                
                let newA = a;
                newA.users = newA.users.reverse().concat(newU).reverse();

                this.setState({
                  activity: newA
                });
              }}>
                <option key={-1} value="">-- Aggiungi utente --</option>
                { this.props.users.filter(u => a.users.every(u2 => u2.id !== u.id))
                  .map((u, i) => {
                    return(
                      <option key={i} value={u.id}>{ u.name }</option>
                    )
                  })
                }
              </select>
            </p>
            <p className="roles"></p>
            <p className="actions"></p>
          </li> }

          { a.users.map((u, i) => {

            const isIstr = u.roles.some(r => r === "Istruttore TSSE");
            const isMon = u.roles.some(r => r === "Monitore CRI");
            const isPat5 = u.roles.some(r => r === "Patente 5");
            const isPat4 = u.roles.some(r => r === "Patente 4");
            const isCs = u.roles.some(r => r === "CS");
            const isTutor = u.roles.some(r => r === "Tutor");
            const isSocc = u.roles.some(r => r === "Soccorritore");
            const isOpem = u.roles.some(r => r === "OPEM");
            const isAdd = u.roles.some(r => r === "Socc in Add");
            const isOsr = u.roles.some(r => r === "OSR");

            return(
              <li key={i}>
                {/* eslint-disable-next-line */}
                <p className="name">{ (a.youreAdmin === 1 || a.youreMod === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <a clickable="true" onClick={() => u.data !== undefined ? this.setState({
                  profile: u
                }) : this.getProfileWithData(u).then(u2 => this.setState({
                  profile: u2
                }))}>{u.name}</a> : u.name }</p>
                { (a.youreAdmin === 1 || a.youreMod === 1) ? <p className="roles">
                { (a.n === 1 || a.n === 2 || a.n === 3 || a.n === 6) && <button onClick={() => this.setState({
                    activityUser: u
                  })} title="Modifica ruoli">
                    { isIstr && (a.n === 1 || a.n === 6) ? <FontAwesomeIcon className="fa-fw" icon={ faUserGraduate } title="Istruttore TSSE (include Tutor, CS, Soccorritore, TSS)" /> : "" }
                    { isMon && a.n === 6 ? <FontAwesomeIcon className="fa-fw" icon={ faBandAid } title="Monitore CRI" /> : "" }
                    { isPat5 && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } title="Patente 5 (include Patente 4)" /> : "" }
                    { isPat4 && (a.n === 1 || a.n === 2) ? <FontAwesomeIcon className="fa-fw" icon={ faCar } title="Patente 4" /> : "" }
                    { isCs && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faCrown } title="Capo Servizio (include Soccorritore, TSS)" /> : "" }
                    { isTutor && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faUserFriends } title="Tutor (include Soccorritore, TSS)" /> : "" }
                    { isSocc && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore (include TSS)" /> : "" }
                    { isOpem && a.n === 3 ? <FontAwesomeIcon className="fa-fw" icon={ faUserAstronaut } title="OPEM" /> : "" }
                    { isAdd && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } title="Soccorritore in Addestramento (include TSS)" /> : "" }
                    { isOsr && (a.n === 1 || a.n === 3) ? <FontAwesomeIcon className="fa-fw" icon={ faPhone } title="Operatore Sala Radio" /> : "" }
                  </button> }
                </p> : <p className="roles">
                  { isIstr && (a.n === 1 || a.n === 6) ? <FontAwesomeIcon className="fa-fw" icon={ faUserGraduate } title="Istruttore TSSE (include Tutor, CS, Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isMon && a.n === 6 ? <FontAwesomeIcon className="fa-fw" icon={ faBandAid } title="Monitore CRI" style={{ cursor: "context-menu" }} /> : "" }
                  { isPat5 && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } title="Patente 5 (include Patente 4)" style={{ cursor: "context-menu" }} /> : "" }
                  { isPat4 && (a.n === 1 || a.n === 2) ? <FontAwesomeIcon className="fa-fw" icon={ faCar } title="Patente 4" style={{ cursor: "context-menu" }} /> : "" }
                  { isCs && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faCrown } title="Capo Servizio (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isTutor && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faUserFriends } title="Tutor (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isSocc && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isOpem && a.n === 3 ? <FontAwesomeIcon className="fa-fw" icon={ faUserAstronaut } title="OPEM" style={{ cursor: "context-menu" }} /> : "" }
                  { isAdd && a.n === 1 ? <FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } title="Soccorritore in Addestramento (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isOsr && (a.n === 1 || a.n === 3) ? <FontAwesomeIcon className="fa-fw" icon={ faPhone } title="Operatore Sala Radio" style={{ cursor: "context-menu" }} /> : "" }
                </p> }
                <p className="actions">
                  
                  {/* <button onClick={() => window.open('mailto:'+u.email, '_blank')}><FontAwesomeIcon className="fa-fw" icon={ faEnvelope } /></button> */}
                  
                  { u.phone !== null ? <button onClick={() => window.open("tel:"+u.phone.toString().substr(0, 3)+" "+u.phone.toString().substr(3, 3)+" "+u.phone.toString().substr(6), "_blank")} title="Chiama"><FontAwesomeIcon className="fa-fw" icon={ faPhone } /></button> : ""}
                  
                  { a.youreAdmin === 1 && u.isAdmin === 0 && u.isMod === 0 && u.id !== this.props.user.id && <button onClick={() => {
                    
                    let newA = a;
                    if(newA.mods === null)
                      newA.mods = [u.id];
                    else
                      newA.mods = newA.mods.concat(u.id);

                    let newU = u;
                    newU.isMod = 1;

                    newA.users = newA.users.map(u2 => {
                      if(u2.id === u.id)
                        return newU;
                      return u2;
                    })
                    this.setState({
                      activity: newA
                    })

                    this.setActivity(newA);
                  }} title="Imposta come aiutante">+<FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                  { a.youreAdmin === 1 && u.isAdmin === 0 && u.isMod !== 0 && u.id !== this.props.user.id && <button onClick={() => {
                    
                    let newA = a;
                    newA.mods = newA.mods.filter(m => m !== u.id);
                    if(newA.mods.length === 0)
                      newA.mods = null;
                    
                    let newU = u;
                    newU.isMod = 0;

                    newA.users = newA.users.map(u2 => {
                      if(u2.id === u.id)
                        return newU;
                      return u2;
                    })
                    this.setState({
                      activity: newA
                    })

                    this.setActivity(newA);
                  }} title="Rimuovi dagli aiutanti">-<FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }

                  { (a.youreAdmin === 1 || a.youreMod === 1) && u.isAdmin === 0 && u.isMod === 0 && u.id !== this.props.user.id && <button style={{ borderColor: "red" }} onClick={() => {
                    
                    let newU = u;
                    newU.subs = newU.subs.filter(s => s !== 'a:'+a.n);

                    this.setUser(newU);
                    
                    let newA = a;
                    newA.users = newA.users.filter(u2 => u2.id !== u.id);

                    this.setState({
                      activity: newA
                    });
                  }} title="Rimuovi dall'attività"><FontAwesomeIcon className="fa-fw" icon={ faUserTimes } /></button> }
                </p>
              </li>
            )
          }) }
        </ul>

      </div>
    )
  }

  viewActivityManageUser() {
    const a = this.state.activity;
    const u = this.state.activityUser;

    let ul = null;
    if(a.n === 1) {
      const isPat5 = u.roles.some(r => r === "Patente 5");
      const isPat4 = u.roles.some(r => r === "Patente 4");
      const isCs = u.roles.some(r => r === "CS");
      const isTutor = u.roles.some(r => r === "Tutor");
      const isSocc = u.roles.some(r => r === "Soccorritore");
      const isAdd = u.roles.some(r => r === "Socc in Add");
      const isOsr = u.roles.some(r => r === "OSR");

      const isIstr = u.roles.some(r => r === "Istruttore TSSE");
      ul = <ul>
        <li key={0}>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /><br />
            Patente 5
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faCar } /><br />
            Patente 4
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faCrown } /><br />
            CS
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUserFriends } /><br />
            Tutor
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUserNurse } /><br />
            Soccorritore
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } /><br />
            Socc in Add
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faPhone } /><br />
            OSR
          </p>
        </li>
        <li key={1}>
          <input type="checkbox" checked={ isPat5 } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Patente 5");
              newU.roles = newU.roles.filter(r => r !== "Patente 4" &&r !== "Soccorritore" && r !== "Socc in Add");
            }
            else
              newU.roles = newU.roles.filter(r => r !== "Patente 5");
            
            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })

            this.setUser(newU);
          }} />
          <input type="checkbox" checked={ isPat4 || isPat5 } disabled={ isPat5 } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Patente 4");
            }
            else
              newU.roles = newU.roles.filter(r => r !== "Patente 4");
            
            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })

            this.setUser(newU);
          }} />
          <input type="checkbox" checked={ isCs || isIstr } disabled={ isIstr } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("CS");
              newU.roles = newU.roles.filter(r => r !== "Soccorritore" && r !== "Socc in Add");
            }
            else
              newU.roles = newU.roles.filter(r => r !== "CS");

            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })
            
            this.setUser(newU);
          }} title={ isIstr ? "Gruppo incluso in Istruttori TSSE" : "" } />
          <input type="checkbox" checked={ isTutor || isIstr /*|| isCs*/ } disabled={ isIstr /*|| isCs*/ } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Tutor");
              newU.roles = newU.roles.filter(r => /*r !== "CS" && */r !== "Soccorritore" && r !== "Socc in Add");
            }
            else
              newU.roles = newU.roles.filter(r => r !== "Tutor");
            
            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })

            this.setUser(newU);
          }} title={ isIstr ? "Gruppo incluso in Istruttori TSSE" : "" } />
          <input type="checkbox" checked={ isSocc || isTutor || isPat5 || isCs || isIstr } disabled={ isTutor || isPat5 || isCs || isIstr } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Soccorritore");
              newU.roles = newU.roles.filter(r => r !== "Socc in Add");
            } else {
              newU.roles = newU.roles.filter(r => r !== "Soccorritore");
            }

            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })
            
            this.setUser(newU);
          }} title={ isTutor || isPat5 || isCs ? "Gruppo incluso in un altro selezionato" : "" } />
          <input type="checkbox" checked={ isAdd } disabled={ isSocc || isTutor || isPat5 || isCs || isIstr } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Socc in Add");
            } else {
              newU.roles = newU.roles.filter(r => r !== "Socc in Add");
            }

            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })
            
            this.setUser(newU);
          }} title={ isSocc || isTutor || isPat5 || isCs || isIstr ? "Gruppo escluso da altri selezionati" : "" } />
          <input type="checkbox" checked={ isOsr } onChange={o => {
            let newU = u;
            if(o.target.checked)
              newU.roles = newU.roles.concat("OSR");
            else
              newU.roles = newU.roles.filter(r => r !== "OSR");

            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })
            
            this.setUser(newU);
          }} />
        </li>
      </ul>
    } else if(a.n === 2) {
      const isPat5 = u.roles.some(r => r === "Patente 5");
      const isPat4 = u.roles.some(r => r === "Patente 4");
      const isTss = u.roles.some(r => r === "TSS");

      const isIstr = u.roles.some(r => r === "Istruttore TSSE");
      const isCs = u.roles.some(r => r === "CS");
      const isTutor = u.roles.some(r => r === "Tutor");
      const isSocc = u.roles.some(r => r === "Soccorritore");
      const isAdd = u.roles.some(r => r === "Socc in Add");
      ul = <ul>
        <li key={0}>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /><br />
            Patente 5
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faCar } /><br />
            Patente 4
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUser } /><br />
            TSS
          </p>
        </li>
        <li key={1}>
        <input type="checkbox" checked={ isPat5 } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("Patente 5");
            newU.roles = newU.roles.filter(r => r !== "Patente 4" && r !== "TSS");
          }
          else
            newU.roles = newU.roles.filter(r => r !== "Patente 5");
          
          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })

          this.setUser(newU);
        }} />
        <input type="checkbox" checked={ isPat4 || isPat5 } disabled={ isPat5 } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("Patente 4");
          } else {
            newU.roles = newU.roles.filter(r => r !== "Patente 4");
          }
          
          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })

          this.setUser(newU);
        }} title={ isPat5 ? "Gruppo incluso in un altro selezionato" : "" } />
        <input type="checkbox" checked={ isTss || isAdd || isPat5 || isSocc || isTutor || isCs || isIstr } disabled={ isAdd || isPat5 || isSocc || isTutor || isCs || isIstr } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("TSS");
          } else {
            newU.roles = newU.roles.filter(r => r !== "TSS");
          }

          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })
          
          this.setUser(newU);
        }} title={ isPat5 || isSocc || isTutor || isCs || isIstr ? "Gruppo incluso in un altro selezionato" : "" } />
        </li>
      </ul>
    } else if(a.n === 3) {
      const isPat5 = u.roles.some(r => r === "Patente 5");
      const isPat4 = u.roles.some(r => r === "Patente 4");
      const isOpem = u.roles.some(r => r === "OPEM");
      ul = <ul>
        <li key={0}>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /><br />
            Patente 5
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faCar } /><br />
            Patente 4
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUserAstronaut } /><br />
            OPEM
          </p>
        </li>
        <li key={1}>
        <input type="checkbox" checked={ isPat5 } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("Patente 5");
            newU.roles = newU.roles.filter(r => r !== "Patente 4");
          } else {
            newU.roles = newU.roles.filter(r => r !== "Patente 5");
          }
          
          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })

          this.setUser(newU);
        }} />
          <input type="checkbox" checked={ isPat4 || isPat5 } disabled={ isPat5 } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("Patente 4");
          } else {
            newU.roles = newU.roles.filter(r => r !== "Patente 4");
          }
          
          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })

          this.setUser(newU);
        }} title={ isPat5 ? "Gruppo incluso in un altro selezionato" : "" } />
        <input type="checkbox" checked={ isOpem } onChange={o => {
          let newU = u;
          if(o.target.checked) {
            newU.roles = newU.roles.concat("OPEM");
          } else {
            newU.roles = newU.roles.filter(r => r !== "OPEM");
          }

          let newA = a;
          newA.users = newA.users.map(u2 => {
            if(u2.id === u.id)
              return newU;
            return u2;
          })
          this.setState({
            activity: newA
          })
          
          this.setUser(newU);
        }} />
        </li>
      </ul>
    } else if(a.n === 6) {
      const isIstr = u.roles.some(r => r === "Istruttore TSSE");
      const isMon = u.roles.some(r => r === "Monitore CRI");
      ul = <ul>
        <li key={0}>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faUserGraduate } /><br />
            Istruttore TSSE
          </p>
          <p>
            <FontAwesomeIcon className="fa-fw" icon={ faBandAid } /><br />
            Monitore CRI
          </p>
        </li>
        <li key={1}>
          <input type="checkbox" checked={ isIstr } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Istruttore TSSE");
            } else {
              newU.roles = newU.roles.filter(r => r !== "Istruttore TSSE");
            }
            
            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })

            this.setUser(newU);
          }} />
          <input type="checkbox" checked={ isMon } onChange={o => {
            let newU = u;
            if(o.target.checked) {
              newU.roles = newU.roles.concat("Monitore CRI");
            } else {
              newU.roles = newU.roles.filter(r => r !== "Monitore CRI");
            }
            
            let newA = a;
            newA.users = newA.users.map(u2 => {
              if(u2.id === u.id)
                return newU;
              return u2;
            })
            this.setState({
              activity: newA
            })

            this.setUser(newU);
          }} />
        </li>
      </ul>
    }

    return(
      <div id="manage-activity-user">
        <button className="action" onClick={() => this.setState({
          activityUser: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faCheck } /></button>
        <h1>{ u.name }</h1>

        { ul }

        { a.youreAdmin === 1 || a.youreMod === 1 ? <p>Per modificare i ruoli personalizzati, contattare <a href={
          "mailto:"+(this.props.users.filter(u => u.rank === "owner" || u.rank === "admin")).map(u => u.email)+
          "?subject="+encodeURIComponent("[CRIsdoGEST] Modifica Ruoli attività")+
          "&body="+encodeURIComponent("In merito all'attività "+a.name+", vorrei aggiungere/rimuovere ...\n\n\n"+this.props.user.name+
            (this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.admins !== null && a.admins.some(a2 => a2 === this.props.user.id)).map(a => "\nResponsabile "+a.name) : "")+
            (this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).length > 0 ? this.props.activities.filter(a => a.mods !== null && a.mods.some(a2 => a2 === this.props.user.id)).map(a => "\nCollaboratore "+a.name) : "")+
            (this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.admins !== null && t.admins.some(a => a === this.props.user.id)).map(t => "\nResponsabile "+t.name) : "")+
            (this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).length > 0 ? this.props.teams.filter(t => t.mods !== null && t.mods.some(a => a === this.props.user.id)).map(t => "\nCollaboratore "+t.name) : "")+
            (this.props.user.rank === "owner" || this.props.user.rank === "admin" ? "\nAmministratore di sistema" : "")+
            (this.props.user.rank === "mod" ? "\nCollaboratore di sistema" : "")
          )
        } target="_blank" rel="noopener noreferrer">un amministratore</a></p> : "" }
      </div>
    )
  }

  viewTeam() {

    const t = this.state.team;

    return(
      <div id="view-team">
        <button className="back" onClick={() => this.setState({
          team: null
        })}><FontAwesomeIcon className="fa-fw" icon={ faUndoAlt } /></button>
        <h1>{ t.name }</h1>

        <h3>Responsabili di Squadra</h3>
        <ul className="managers">

          { t.users.filter(u => u.isAdmin).map((u, i) => {
            return(
              <li key={i}>
                <u>{ u.name }</u>
              </li>
            )
          }) }
          { t.users.filter(u => u.isMod).map((u, i) => {
            return(
              <li key={i}>
                <FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } title="Aiutante" style={{ cursor: "context-menu" }} />
                { u.name }
              </li>
            )
          }) }
        </ul>

        { (t.links.length > 0 || t.youreAdmin !== 0 || t.youreMod !== 0) && <div>
          <h3>Collegamenti</h3>

          <ul style={{ display: "flex", flexDirection: "row", flexWrap: "wrap" }}>
            { t.links.map((l, i) => {
              return(
                <li key={i}>
                  <button onClick={() => {
                    if(t.youreAdmin !== 0 || t.youreMod !== 0) {
                      confirmAlert({
                        title: "Amministrazione",
                        message: "Cosa vuoi fare?",
                        buttons: [
                          {
                            label: "Annulla"
                          },
                          {
                            label: "Modifica",
                            onClick: () => {
                              let newT = t;

                              newT.newLink = l;

                              this.setState({
                                team: newT
                              })
                            }
                          },
                          {
                            label: "Apri",
                            onClick: () => window.open(l.url, "_blank")
                          }
                        ]
                      });
                    } else {
                      window.open(l.url, "_blank");
                    }
                  }} title={ l.url }>
                    { l.name }
                  </button>
                </li>
              )
            }) }
            { (t.youreAdmin !== 0 || t.youreMod !== 0) && <button active={ t.newLink !== undefined ? "true" : "" } onClick={() => {
              let newT = t;

              if(newT.newLink === undefined) {
                newT.newLink = {
                  name: "",
                  url: ""
                }
              } else {
                delete newT.newLink;
              }

              this.setState({
                team: newT
              })
            }} title="Aggiungi collegamento"><FontAwesomeIcon className="fa-fw" icon={ faPlus } /></button> }
          </ul>
          { t.newLink !== undefined && <div>
            <input type="text" value={ t.newLink.name } onChange={o => {
              let newT = t;

              newT.newLink.name = o.target.value;
              this.setState({
                team: newT
              })
            }} placeholder="Nome" />
            <span>
              <input type="url" value={ t.newLink.url } onChange={o => {
                let newT = t;
                
                newT.newLink.url = o.target.value;
                this.setState({
                  team: newT
                })
              }} placeholder="URL" />
              <button onClick={() => window.open("https://drive.google.com/", "_blank")}><FontAwesomeIcon className="fa-fw" icon={ faGoogleDrive } title="Apri Google Drive" /></button>
            </span>
            <span>
              { t.newLink.n !== undefined && t.newLink.name !== "" && t.newLink.url !== "" && <button onClick={() => {
                let newT = t;
                newT.links = newT.links.filter(l2 => l2.n !== t.newLink.n);

                this.delLink(t.newLink.n);
                delete t.newLink;
                this.setTeam(newT);

                this.setState({
                  team: newT
                })
              }}><FontAwesomeIcon className="fa-fw" icon={ faTrash } /></button> }
              { t.newLink.name !== "" && t.newLink.url !== "" && <button onClick={() => {
                let newT = t;
                let newL = newT.newLink;
                this.setLink(newL).then(n => {
                  newL.n = n;

                  if(newT.links.some(l => l.n === n)) {
                    newT.links = newT.links.map(l => {
                      if(l.n === n)
                        return newL;
                      return l;
                    });
                  } else {
                    newT.links = newT.links.concat(newL);
                  }
                  delete newT.newLink;

                  this.setState({
                    team: newT
                  })
                  this.setTeam(newT);
                });
              }}><FontAwesomeIcon className="fa-fw" icon={ faCheck } />Salva</button> }
            </span>
          </div> }
        </div> }

        <h3>Utenti</h3>
        <ul id="users">

        { (t.youreAdmin === 1 || t.youreMod === 1) && <li key={-1}>
            <p className="name">
              <select value="" onChange={o => {
                
                let newU = this.props.users.filter(u => u.id.toString() === o.target.value)[0];
                newU.subs = newU.subs.concat('t:'+t.n);
                newU.isSub = 1;
                newU.isAdmin = 0;
                newU.isMod = 0;

                this.setUser(newU);
                
                let newT = t;
                newT.users = newT.users.reverse().concat(newU).reverse();

                this.setState({
                  team: newT
                });
              }}>
                <option key={-1} value="">-- Aggiungi utente --</option>
                { this.props.users.filter(u => t.users.every(u2 => u2.id !== u.id))
                  .map((u, i) => {
                    return(
                      <option key={i} value={u.id}>{ u.name }</option>
                    )
                  })
                }
              </select>
            </p>
            <p className="roles"></p>
            <p className="actions"></p>
          </li> }

          { t.users.map((u, i) => {

            const isIstr = u.roles.some(r => r === "Istruttore TSSE");
            const isMon = u.roles.some(r => r === "Monitore CRI");
            const isPat5 = u.roles.some(r => r === "Patente 5");
            const isPat4 = u.roles.some(r => r === "Patente 4");
            const isCs = u.roles.some(r => r === "CS");
            const isTutor = u.roles.some(r => r === "Tutor");
            const isSocc = u.roles.some(r => r === "Soccorritore");
            const isOpem = u.roles.some(r => r === "OPEM");
            const isAdd = u.roles.some(r => r === "Socc in Add");
            const isOsr = u.roles.some(r => r === "OSR");

            return(
              <li key={i}>
                {/* eslint-disable-next-line */}
                <p className="name">{ (t.youreAdmin === 1 || t.youreMod === 1 || this.props.user.rank === "owner" || this.props.user.rank === "admin" || this.props.user.rank === "mod") ? <a clickable="true" onClick={() => u.data !== undefined ? this.setState({
                  profile: u
                }) : this.getProfileWithData(u).then(u2 => this.setState({
                  profile: u2
                }))}>{u.name}</a> : u.name }</p>
                <p className="roles">
                  { isIstr ? <FontAwesomeIcon className="fa-fw" icon={ faUserGraduate } title="Istruttore TSSE (include Tutor, CS, Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isMon ? <FontAwesomeIcon className="fa-fw" icon={ faBandAid } title="Monitore CRI" style={{ cursor: "context-menu" }} /> : "" }
                  { isPat5 ? <FontAwesomeIcon className="fa-fw" icon={ faAmbulance } title="Patente 5 (include Patente 4)" style={{ cursor: "context-menu" }} /> : "" }
                  { isPat4 ? <FontAwesomeIcon className="fa-fw" icon={ faCar } title="Patente 4" style={{ cursor: "context-menu" }} /> : "" }
                  { isCs ? <FontAwesomeIcon className="fa-fw" icon={ faCrown } title="Capo Servizio (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isTutor ? <FontAwesomeIcon className="fa-fw" icon={ faUserFriends } title="Tutor (include Soccorritore, TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isSocc ? <FontAwesomeIcon className="fa-fw" icon={ faUserNurse } title="Soccorritore (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isOpem ? <FontAwesomeIcon className="fa-fw" icon={ faUserAstronaut } title="OPEM" style={{ cursor: "context-menu" }} /> : "" }
                  { isAdd ? <FontAwesomeIcon className="fa-fw" icon={ faChalkboardTeacher } title="Soccorritore in Addestramento (include TSS)" style={{ cursor: "context-menu" }} /> : "" }
                  { isOsr ? <FontAwesomeIcon className="fa-fw" icon={ faPhone } title="Operatore Sala Radio" style={{ cursor: "context-menu" }} /> : "" }
                </p>
                <p className="actions">
                  
                  {/* <button onClick={() => window.open('mailto:'+u.email, '_blank')}><FontAwesomeIcon className="fa-fw" icon={ faEnvelope } /></button> */}
                  
                  { u.phone !== null ? <button onClick={() => window.open("tel:"+u.phone.toString().substr(0, 3)+" "+u.phone.toString().substr(3, 3)+" "+u.phone.toString().substr(6), "_blank")} title="Chiama"><FontAwesomeIcon className="fa-fw" icon={ faPhone } /></button> : ""}
                  
                  { t.youreAdmin === 1 && u.isAdmin === 0 && u.isMod === 0 && u.id !== this.props.user.id && <button onClick={() => {
                    
                    let newT = t;
                    if(newT.mods === null)
                      newT.mods = [u.id];
                    else
                      newT.mods = newT.mods.concat(u.id);

                    let newU = u;
                    newU.isMod = 1;

                    newT.users = newT.users.map(u2 => {
                      if(u2.id === u.id)
                        return newU;
                      return u2;
                    });

                    this.setState({
                      team: newT
                    });

                    this.setTeam(newT);
                  }} title="Imposta come aiutante">+<FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }
                  { t.youreAdmin === 1 && u.isAdmin === 0 && u.isMod !== 0 && u.id !== this.props.user.id && <button onClick={() => {
                    
                    let newT = t;
                    newT.mods = newT.mods.filter(m => m !== u.id);
                    if(newT.mods.length === 0)
                      newT.mods = null;
                    
                    let newU = u;
                    newU.isMod = 0;

                    newT.users = newT.users.map(u2 => {
                      if(u2.id === u.id)
                        return newU;
                      return u2;
                    });

                    this.setState({
                      team: newT
                    });

                    this.setTeam(newT);
                  }} title="Rimuovi dagli aiutanti">-<FontAwesomeIcon className="fa-fw" icon={ faHandsHelping } /></button> }

                  { (t.youreAdmin === 1 || t.youreMod === 1) && u.isAdmin === 0 && u.isMod === 0 && u.id !== this.props.user.id && <button style={{ borderColor: "red" }} onClick={() => {
                    
                    let newU = u;
                    newU.subs = newU.subs.filter(s => s !== 't:'+t.n);

                    this.setUser(newU);
                    
                    let newT = t;
                    newT.users = newT.users.filter(u2 => u2.id !== u.id);

                    this.setState({
                      team: newT
                    });
                  }} title="Rimuovi dalla squadra"><FontAwesomeIcon className="fa-fw" icon={ faUserTimes } /></button> }
                </p>
              </li>
            )
          }) }
        </ul>
      </div>
    )
  }

  setChronoStamp() {

    if(this.state.chronoStart !== null) {

      let s = Math.floor( (new Date().getTime()-this.state.chronoStart) / 1000 );

      const h = Math.floor(s / 3600);
      s -= h*3600;

      const m = Math.floor(s / 60);
      s -= m*60;

      this.setState({
        chronoStamp: (h.toString().length === 2 ? h : "0"+h) + ":" +
          (m.toString().length === 2 ? m : "0"+m) + ":" +
          (s.toString().length === 2 ? s : "0"+s)
      });
    } else {

      this.setState({
        chronoStamp: "00:00:00"
      })
    }
  }

  setEvent(e) {
    return fetch('https://api.cristinaweb.rossini.dev/set_event.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        event: e
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {
        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setProfile(p) {
    return fetch('https://api.cristinaweb.rossini.dev/set_profile.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: p
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {
        let newP = p;
        newP.data = newP.data.filter(d => d.value !== null);
        newP.modif = undefined;
        this.setState({
          profile: newP
        })
      } else {
        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    })
  }

  setUser(u) {
    return fetch('https://api.cristinaweb.rossini.dev/set_user.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: u
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {
        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setTeam(t) {
    return fetch('https://api.cristinaweb.rossini.dev/set_team.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        team: t
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setActivity(a) {
    return fetch('https://api.cristinaweb.rossini.dev/set_activity.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        activity: a
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setLink(l) {
    return fetch('https://api.cristinaweb.rossini.dev/set_link.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: l
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        return responseJson.n;
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
        return null;
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  delLink(n) {
    return fetch('https://api.cristinaweb.rossini.dev/del_link.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        link: n
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok !== 1) {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setKey(k) {
    return fetch('https://api.cristinaweb.rossini.dev/set_key.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.state.user.id,
        key: k
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        let newU = this.state.user;
        newU.keys = newU.keys.concat(k);

        this.setState({
          user: newU,
          newKey: undefined
        })
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  delKey(n) {
    return fetch('https://api.cristinaweb.rossini.dev/del_key.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        key: n
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        let newU = this.state.user;
        newU.keys = newU.keys.filter(k => k.n !== n);

        this.setState({
          user: newU
        })
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setData(d) {
    return fetch('https://api.cristinaweb.rossini.dev/set_user_data.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        data: d
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        const newP = this.state.profile;
        newP.data = newP.data.concat(responseJson.data);

        this.setState({
          user: newP,
          newData: undefined
        })
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  getProfileWithData(p) {
    return fetch('https://api.cristinaweb.rossini.dev/get_user_data.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: p.id
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      const newP = p;

      if(responseJson.ok === 1) {
        newP.data = responseJson.data;
        return newP;
      } else {
        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
        newP.data = [];
        return newP;
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  setNaviFav(s) {
    return fetch('https://api.cristinaweb.rossini.dev/set_user_navi.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        user: this.state.user.id,
        search: s
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        const newU = this.state.user;
        newU.navi = newU.navi.concat(responseJson.navi);

        this.setState({
          user: newU,
          naviLocation: "",
          naviStreet: "",
          naviNumber: "",
          naviCross: "",
          naviFav: false,
        })
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  delNaviFav(n) {
    return fetch('https://api.cristinaweb.rossini.dev/del_user_navi.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        navi: n.n
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      if(responseJson.ok === 1) {

        const newU = this.state.user;
        newU.navi = newU.navi.filter(n2 => n2.n !== n.n);

        this.setState({
          user: newU
        })
      } else {

        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      confirmAlert({
        title: "Errore",
        message: error.toString().indexOf("Failed to fetch") !== -1 ? "Sei offline!" : error.toString(),
        buttons: [
          {
            label: "OK"
          }
        ]
      });

      console.error(error);
    });
  }

  getShifts() {
    return fetch('https://api.cristinaweb.rossini.dev/get_shifts.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        id: this.props.user.id,
        mode: "past",
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {
      this.setState({
        shifts: responseJson
      })
    })
    .catch((error) => {
      console.error(error);
    });
  }

  loginRetry() {
    fetch('https://api.cristinaweb.rossini.dev/login.php', {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email: this.props.user.email,
        gid: this.props.user.googleId,
        name: this.props.user.name,
      }),
    })
    .then((response) => response.json())
    .then((responseJson) => {

      if(responseJson.ok === 1) {
        const loginData = responseJson;
        loginData.user.googleId = this.props.user.googleId;
        loginData.user.offline = false;
        localStorage.setItem("login", JSON.stringify(loginData));

        confirmAlert({
          title: "Fatto",
          message: "Sei di nuovo online!",
          buttons: [
            {
              label: "OK"
            }
          ]
        });

        this.setState({
          user: loginData.user,
          loginRetryTime: null
        });

        this.props.loginRetry(loginData);
      } else {
        confirmAlert({
          title: "Errore",
          message: responseJson.info,
          buttons: [
            {
              label: "OK"
            }
          ]
        });
      }
    })
    .catch((error) => {
      this.setState({
        loginRetryTime: setTimeout(() => this.loginRetry(), 10000)
      });

      console.error(error);
    });
  }
}

export default Home;