import React from 'react';
import ReactDOM from 'react-dom';

import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { confirmAlert } from 'react-confirm-alert';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
//serviceWorker.unregister();
serviceWorker.register({
    onUpdate: registration => {
        confirmAlert({
            title: "Aggiornamento installato!",
            message: "Potrebbe essere necessario ricaricare la pagina",
            buttons: [
                {
                    label: "OK"
                }
            ],
            willUnmount: () => window.location.reload()
        });
        if(registration && registration.waiting) {
            registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        }
    }
});