import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faAmbulance, faUserMd, faUserTie } from '@fortawesome/free-solid-svg-icons';

import './Contatti.css';

class Contatti extends React.Component {
  
  render() {
    return(
      <div id="contatti">
        <h1>Contatti</h1>

        <ul>
          <li>
            <h3>Stazionamenti</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:02 527 2137', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faHome } /></p>
            <p className="text">Sede</p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:02 9823 0276', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faHome } /></p>
            <p className="text">Stazionamento Vizzolo</p>
          </li>
          <li>
            <h3>Mezzi</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 421 8055', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
            <p className="text">1447</p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 421 8203', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
            <p className="text">1446</p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 421 8021', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
            <p className="text">1135</p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 421 8074', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faAmbulance } /></p>
            <p className="text">1445</p>
          </li>
          <li>
            <h3>Di servizio</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:02 5287 1308', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">
              SOREU Metropolitana<br />
              <i>Solo su indisponibilità telefono AREU</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:800 118 123', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">
              Miledy - SOREU Metropolitana<br />
              <i>Solo su indisponibilità telefono AREU</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:02 5287 1510', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">
              SOREU Pianura<br />
              <i>Solo su indisponibilità telefono AREU</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:800 400 118', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">
              SOREU Assistenze Sanitarie<br />
              <i>Solo con telefono indicato</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:116 117', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">Continuità Assistenziale</p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:112', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserMd } /></p>
            <p className="text">Emergenza TSS - 112</p>
          </li>
          <li>
            <h3>Responsabili di Attività</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:342 049 9681', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Alberto Zaffoni<br />
              <i>Responsabile del Trasporto Sanitario Semplice</i><br />
              <i>Responsabile Sala Radio</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:340 916 6071', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Alessandro Simeone<br />
              <i>Responsabile Logistica (Materiale)</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:392 178 2426', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Andrea Rossini<br />
              <i>Responsabile del Soccorso Sanitario Extraospedaliero</i>{/**<br />
              <i>Responsabile del Servizio Civile</i>*/}
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:349 568 7385', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Camilla Longone<br />
              <i>Responsabile Mensa di Bresso</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:335 662 2772', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Cesare Bruschi<br />
              <i>Responsabile Donazioni del Sangue</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:349 721 3646', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Claudio Zambelli<br />
              <i>Responsabile della Scuola di Formazione</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:345 851 5015', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Davide Cunial<br />
              <i>Responsabile del Trasporto Sanitario Semplice</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:338 587 6622', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Elisabetta Salvati<br />
              <i>Responsabile Dipendenze</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:338 587 6622', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Federica Piersanti<br />
              <i>Responsabile Progetto Ass. Sociali / Università</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 683 4921', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Guido Odetti<br />
              <i>Responsabile Logistica (Vestiario)</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:366 283 5261', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Margherita Sandolo<br />
              <i>Responsabile del Servizio Civile</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:346 404 6930', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Maurizio Bertozzi<br />
              <i>Responsabile delle Emergenze</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:348 911 6039', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Roberto Masoch<br />
              <i>Responsabile Motorizzazione</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:349 784 8764', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Simona Favalli<br />
              <i>Responsabile Dipendenze</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:335 768 1749', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Stefano Burgarello<br />
              <i>Responsabile Assistenza San Siro</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:347 962 1265', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Tommaso Palvarini<br />
              <i>Responsabile Unità di Strada</i>
            </p>
          </li>
          {/*<li clickable="true" onClick={() => window.open('tel:339 259 6024', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Grazia Mele<br />
              <i>Responsabile Ufficio Soci</i>
            </p>
          </li>*/}
          <li>
            <h3>Coordinatori di Area</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:340 916 6071', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Alessandro Simeone<br />
              <i>Coordinatore Cooperazione</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:345 924 8915', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Alisia Madè<br />
              <i>Coordinatore del Volontariato</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:349 721 3646', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Claudio Zambelli<br />
              <i>Coordinatore della Scuola di Formazione</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:375 559 0257', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Fabio Pellegrino<br />
              <i>Direttore Sanitario</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:347 086 2339', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Francesca Momesso<br />
              <i>Coordinatore Progetti Sociali</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:333 306 0667', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Loris Bellini<br />
              <i>Coordinatore Amministrazione ed Uff. Personale</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:366 648 1242', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Sara Antonelli<br />
              <i>Coordinatore Fundraising</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:339 815 9220', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Simona Chiametti<br />
              <i>Coordinatore Comunicazione</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:347 243 5694', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Stefano Gatti<br />
              <i>Coordinatore Progetti Sociali</i>
            </p>
          </li>
          <li>
            <h3>Consiglio Direttivo</h3>
          </li>
          <li clickable="true" onClick={() => window.open('tel:335 630 0948', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Maximilian Busnelli<br />
              <i>Presidente</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:347 866 6250', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Mauro Turrini<br />
              <i>Vice Presidente</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:333 801 3296', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Danila Aprile<br />
              <i>Consigliere</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:333 859 9672', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Irene Cinquini<br />
              <i>Consigliere</i>
            </p>
          </li>
          <li clickable="true" onClick={() => window.open('tel:349 521 1387', '_blank')}>
            <p className="icon"><FontAwesomeIcon className="fa-fw" icon={ faUserTie } /></p>
            <p className="text">
              Umberto Muri<br />
              <i>Consigliere Rappresentante della Gioventù</i>
            </p>
          </li>
        </ul>
      </div>
    )
  }
}

export default Contatti;